/**
 * Appointment Planner - Care Providers REST API
 * Appointment Planner Care Providers Service REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: valeriu.craciun@kronsoft.ro
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Allowed display intervals Enum Data Transfer Object
 */
export type AllowedDisplayIntervalsEnumDTO = 5 | 10 | 15 | 20 | 25 | 30;

export const AllowedDisplayIntervalsEnumDTO = {
    NUMBER_5: 5 as AllowedDisplayIntervalsEnumDTO,
    NUMBER_10: 10 as AllowedDisplayIntervalsEnumDTO,
    NUMBER_15: 15 as AllowedDisplayIntervalsEnumDTO,
    NUMBER_20: 20 as AllowedDisplayIntervalsEnumDTO,
    NUMBER_25: 25 as AllowedDisplayIntervalsEnumDTO,
    NUMBER_30: 30 as AllowedDisplayIntervalsEnumDTO
};

