import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { CmsPageTypeUrl } from '@ap-angular/shared/schema';

@Injectable({
	providedIn: 'root'
})
export class IsPageCMSGuard implements CanActivate {

	constructor(
		private router: Router
	){
	}

	canActivate(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		const VALID = route.params && route.params.content &&
					  (Object.values(CmsPageTypeUrl) as Array<string>).indexOf(route.params.content) > -1;
		if (!VALID) {
			this.router.navigate(['/']).then();
		}
		return VALID;
	}
}
