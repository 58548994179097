<ng-container *ngIf="stepArray && stepArray.length > 0 && current !== undefined">
    <ng-container *ngFor="let data of stepArray; let i = index">
        <main [@AnimationStepChange] *ngIf="i === current && !showDrop">
            <header>
                <div *ngIf="data.icon || data.title" class="flex-center flex-column">
                    <mat-icon *ngIf="data.icon" color="{{data.icon?.color}}" [ngClass]="data.icon.zoom ? 'zoom-' + data.icon.zoom : ''">
                        {{data.icon.name}}
                    </mat-icon>
                    <div *ngIf="data.title" [attr.color]="data.title?.color">{{data.title.content | translate}}</div>
                </div>

                <div class="process" *ngIf="data.info && data.percent !== undefined">
                    <div class="process-circle" [ngClass]="'p' + data.percent"></div>
                    <div class="process-text">{{data.info}}</div>
                </div>

                <div class="element-spacer"></div>

                <button mat-icon-button color="primary" *ngIf="!data.hideMinimize" (click)="minimize = !minimize">
                    <mat-icon [svgIcon]="minimize ? 'ap-maximize' : 'ap-minimize'"></mat-icon>
                </button>
            </header>

            <div [@AnimationSizeChange]="minimize" *ngIf="!minimize">
                <section *ngIf="data.description" [innerHTML]="data.description | translate"></section>

                <detail *ngIf="data.detail" [innerHTML]="data.detail | translate"></detail>

                <footer *ngIf="data.buttons">
                    <button mat-button *ngFor="let button of data.buttons"
                            [color]="button.color"
                            [ngClass]="button.type"
                            (click)="action(button)">
                        {{button.name | translate}}
                    </button>
                </footer>
            </div>
        </main>
    </ng-container>

    <main [@AnimationStepChange] *ngIf="showDrop">
        <header>
            <ng-container *ngIf="drop.title">{{drop.title}}</ng-container>
        </header>
        <section *ngIf="drop.description" [innerHTML]="drop.description | translate"></section>
        <detail *ngIf="drop.reload">
            <div class="switch" [class.checked]="reload">
                <input type="checkbox" id="reload" class="switch__input" (change)="reload = !reload">
                <label for="reload" class="switch__label">{{drop.reload | translate}}</label>
            </div>
        </detail>
        <footer *ngIf="drop.buttons">
            <button mat-button *ngFor="let button of drop.buttons"
                    [color]="button.color"
                    [ngClass]="button.type"
                    (click)="action(button)">
                {{button.name | translate}}
            </button>
        </footer>
    </main>
</ng-container>

<div class="app-kds-onboarding-tour-backdrop"></div>
