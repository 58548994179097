import { createAction, props } from '@ngrx/store';
import { EnvironmentUpdateEnum } from '@ap-angular/shared/environment';
import { MediaQuerySizeType } from '@ap-angular/service/application';

export const VersionAction = createAction(
	'[Application] Version',
	props<{ payload: { version: string, versionUpdate: EnvironmentUpdateEnum } }>()
);

export const MediaQuerySizeChanged = createAction(
	'[Application] Media Query Size Changed',
	props<{ mediaQuerySize: MediaQuerySizeType }>()
);

export const AppLanguage = createAction(
	'[Application] App Language',
	props<{ language: string }>()
);

export const AppSidenav = createAction(
	'[Application] App Sidenav',
	props<{ sidenav: string }>()
);

export const ShowSearch = createAction(
	'[Application] Show Search',
	props<{ showSearch: boolean }>()
);

export const ClearState = createAction(
	'[Application] Clear State'
);

export const PositionOfSearchInput = createAction(
	'[Application] Position Of Search Input',
	props<{ positionOfSearchInput: number }>()
);
