<mat-sidenav-container>
    <mat-sidenav #sidenav mode="over" color="primary">
        <ng-container [ngTemplateOutlet]="menu"></ng-container>
    </mat-sidenav>

    <!--    <mat-sidenav position="end">End</mat-sidenav>-->

    <mat-sidenav-content>
        <ng-container [ngTemplateOutlet]="header"></ng-container>
        <ng-content select="[template-loading]"></ng-content>
        <ng-content select="[template-content]"></ng-content>

        <router-outlet><!-- Here goes the magic navigation --></router-outlet>

        <ng-container [ngTemplateOutlet]="footer"></ng-container>
    </mat-sidenav-content>
</mat-sidenav-container>

<ng-template #menu>
    <mat-toolbar class="no-padding sidenav-menu">
        <div class="menu-logo-container">
            <img class="menu-logo" src="assets/medDay-logo-white-long.svg" alt="Med-Day" />
        </div>
    </mat-toolbar>

    <div class="scroll" *ngIf="(permission$ | async) as permission">
        <ap-provider-institution-select (closeSidenav)="sidenav.toggle()"></ap-provider-institution-select>

        <div class="line-divider"></div>

        <mat-nav-list class="no-padding margin-bottom provider-menu">
            <ng-container *ngIf="!employeeLimitedAccess; else limitedAccess">
                <a mat-list-item routerLinkActive="active-list-item"
                   *ngIf="permission.includes('CALENDAR_VIEW')"
                   [routerLink]="PAGE.PROVIDER_CALENDAR.fullpath"
                   (click)="sidenav.toggle()">
                    <span class="mat-list-text hide-on-close">{{'Pages.Provider.Calendar.Label' | translate}}</span>
                </a>

            </ng-container>
            <ng-template #limitedAccess>
                <a mat-list-item routerLinkActive="active-list-item" class="disabled"
                   [matTooltip]="'App.Tooltip.Resource_Unlinked_To_Employee_Account' | translate"
                   *ngIf="permission.includes('CALENDAR_VIEW')">
                    <span class="mat-list-text hide-on-close">{{'Pages.Provider.Calendar.Label' | translate}}</span>
                </a>

            </ng-template>

            <a mat-list-item routerLinkActive="active-list-item" *ngIf="permission.includes('PATIENT_MANAGEMENT_VIEW')"
                [routerLink]="PAGE.PROVIDER_PATIENT.fullpath" (click)="sidenav.toggle()">
                <span class="mat-list-text hide-on-close">{{'Api.ProviderPatientManagement.Title' | translate}}</span>
            </a>

            <ng-container *ngIf="permission.includes('PROFILE_DATA_VIEW')">
                <a mat-list-item [class.active-group]="activeRoute === 'profile'" (click)="activeSubmenu[0] = !activeSubmenu[0]" >
                    <span class="mat-list-text hide-on-close">{{'Pages.Provider.Information.Menu_Header' | translate}}</span>
                    <span matSuffix><mat-icon [ngClass]="{'rotate-270' : !activeSubmenu[0]}">keyboard_arrow_down</mat-icon></span>
                </a>
                <ap-provider-menu-aside [ngClass]="{'hide' : !activeSubmenu[0]}"
                                        (click)="sidenav.toggle()">
                </ap-provider-menu-aside>
            </ng-container>

            <a mat-list-item routerLinkActive="active-list-item" *ngIf="permission.includes('USERS_MANAGEMENT_VIEW')"
               [routerLink]="PAGE.PROVIDER_USER_MANAGEMENT.fullpath" (click)="sidenav.toggle()">
                <span class="mat-list-text hide-on-close">{{'Api.ProviderUserManagement.Title' | translate}}</span>
            </a>
        </mat-nav-list>
        
        <div class="element-spacer"></div>

        <mat-hint *ngIf="!env.production" class="version margin">v {{env.version}}</mat-hint>
    </div>
</ng-template>

<ng-template #header>
    <mat-toolbar class="template-header template-header--blue">
        <button mat-icon-button class="hide-on-extraLargeVar-and-up show-on-largeVar-and-down" (click)="sidenav.toggle()">
            <mat-icon>menu</mat-icon>
            <span class="red-dot" *ngIf="newsList && newsList.length > 0"></span>
        </button>

        <div class="header-logo-container">
            <img class="header-logo" src="assets/med-day-logo-small.svg" alt="Med-Day" />
        </div>

        <ng-content header-content select="[template-header]"></ng-content>


        <!-- <ap-provider-warnings></ap-provider-warnings> -->

        <!-- <button *ngIf="newsList && newsList.length > 0" mat-icon-button class="margin-left-S hide-on-med-and-down" (click)="goToReleasePage()">
            <mat-icon>new_releases</mat-icon>
            <span class="red-dot"></span>
        </button> -->
        <div class="top-bar-action-buttons-container" *ngIf="(permission$ | async) as permission">
            <div class="top-bar-action-buttons-menu hide-on-largeVar-and-down">
                <ap-provider-institution-select></ap-provider-institution-select>


                <ng-container *ngIf="!employeeLimitedAccess; else limitedAccess">
                    <a  class="top-bar-action-button"  routerLinkActive="top-bar-action-button--active"
                       *ngIf="permission.includes('CALENDAR_VIEW')"
                       [routerLink]="PAGE.PROVIDER_CALENDAR.fullpath">
                        <span >{{'Pages.Provider.Calendar.Label' | translate}}</span>
                    </a>
    
                </ng-container>
                <ng-template #limitedAccess>
                    <a class="top-bar-action-button"  routerLinkActive="top-bar-action-button--active" class="disabled"
                       [matTooltip]="'App.Tooltip.Resource_Unlinked_To_Employee_Account' | translate"
                       *ngIf="permission.includes('CALENDAR_VIEW')">
                        <span class="mat-list-text">{{'Pages.Provider.Calendar.Label' | translate}}</span>
                    </a>
                </ng-template>

                <a class="top-bar-action-button"  routerLinkActive="top-bar-action-button--active" *ngIf="permission.includes('PATIENT_MANAGEMENT_VIEW')"
                   [routerLink]="PAGE.PROVIDER_PATIENT.fullpath">
                    <span >{{'Api.ProviderPatientManagement.Title' | translate}}</span>
                </a>

                <ng-container *ngIf="permission.includes('PROFILE_DATA_VIEW')">
                    <a  class="top-bar-action-button flex-center"   [matMenuTriggerFor]="providerMenu" [disableRipple]="true"
                    (menuOpened)="opened = true;" (menuClosed)="opened = false;" >
                        <span >{{'Pages.Provider.Information.Menu_Header' | translate}}</span>
                        <mat-icon>keyboard_arrow_down</mat-icon>
                    </a>
                    <mat-menu #providerMenu="matMenu"   xPosition="after" yPosition="below">
                        <ap-provider-menu-aside  ></ap-provider-menu-aside>
                    </mat-menu>
                </ng-container>
    
                <a class="top-bar-action-button"  routerLinkActive="top-bar-action-button--active" *ngIf="permission.includes('USERS_MANAGEMENT_VIEW')"
                   [routerLink]="PAGE.PROVIDER_USER_MANAGEMENT.fullpath">
                    <span >{{'Api.ProviderUserManagement.Title' | translate}}</span>
                </a>

            </div>
            
            <div class="top-bar-action-buttons-icons">
                <button mat-icon-button [routerLink]="PAGE.PROVIDER_PATIENT_WAITING_LIST.fullpath"><mat-icon [svgIcon]="'ap-waiting-list-icon'"></mat-icon></button>
                <button mat-icon-button [routerLink]="PAGE.PROVIDER_MESSAGE.fullpath"><mat-icon [svgIcon]="'ap-messages-icon'" [disabled]="employeeLimitedAccess"></mat-icon></button>

                <button mat-icon-button [matMenuTriggerFor]="helpMenu" [disableRipple]="true"
                (menuOpened)="opened = true;" (menuClosed)="opened = false;">
                    <span class="red-dot" *ngIf="newsList && newsList.length > 0"></span>
                    <mat-icon [svgIcon]="'ap-help-icon'"></mat-icon>
                </button>
                <button mat-icon-button *ngIf="showSearchButton" matSuffix (click)="searchClicked($event)"><mat-icon>search</mat-icon></button>

                <mat-menu #helpMenu="matMenu" xPosition="after" yPosition="below">
                    <mat-nav-list class="submenu no-padding">
                        <a mat-list-item routerLinkActive="active-list-item" [routerLink]="PAGE.PROVIDER_FAQ.fullpath">
                            {{'Pages.FAQ.Label' | translate}}
                        </a>
                        <a mat-list-item routerLinkActive="active-list-item" [routerLink]="PAGE.PROVIDER_CONTACT.fullpath">
                            {{'Pages.Provider.Contact.Label' | translate}}
                        </a>
                        <a mat-list-item routerLinkActive="active-list-item" [routerLink]="'/' + PAGE.PROVIDER.path + '/' + PAGE.LEGAL.path">
                            {{'Pages.Provider.Legal.Title' | translate}}
                        </a>
                        <a mat-list-item routerLinkActive="active-list-item" [routerLink]="PAGE.PROVIDER_NEWS.fullpath" (click)="goToReleasePage()">
                            <span class="mat-list-text">
                                {{'Pages.Provider.News.Title' | translate}}
                                <span class="red-dot" *ngIf="newsList && newsList.length > 0"></span>
                            </span>
                        </a>
                    </mat-nav-list>
                </mat-menu>
            </div>
        </div>

        <button #headerUser class="header-user" mat-button [matMenuTriggerFor]="appMenu">
            <div class="header-user-content">

                <mat-icon>account_circle</mat-icon>
                <div class="margin-left-S text-trim show-on-extraLargeVar-and-up hide-on-largeVar-and-down">{{ (name$ | async) }}</div>
                <mat-icon>keyboard_arrow_down</mat-icon>
            </div>
        </button>
    </mat-toolbar>
</ng-template>

<ng-template #footer>
    <!--    <mat-toolbar class="template-footer" color="transparent">-->
    <ng-content footer-content select="[template-footer]"></ng-content>
    <!--    </mat-toolbar>-->
</ng-template>

<mat-menu #appMenu="matMenu">
    <button mat-menu-item color="primary" type="button" [routerLink]="PAGE.PROVIDER_SETTINGS_DATA.fullpath">
        {{'Pages.Provider.User.Settings.Title' | translate}}
    </button>

    <button mat-menu-item color="primary" type="button" *ngIf="(provider$ | async) as provider"
            (click)="widget({profileLink: provider.profileLink, widgetToken: provider.widgetToken})">
        {{'App.Widget_Integration' | translate}}
    </button>

    <button mat-menu-item color="primary" (click)="logout()">
        {{'Pages.Logout.Label' | translate}}
    </button>

</mat-menu>
