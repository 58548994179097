import { createReducer, on, Action } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';

import * as UserActions from './user.actions';

export const USER_FEATURE_KEY = 'user';

export interface State {
	expires?: string;
	ped?: number;
	pids: {[key: number]: string};
	defaultPid?: number;
	username?: string;
	permissions?: Array<string>;
	uan?: string;
	loading: boolean;
	error?: HttpErrorResponse | null;
}

export interface UserPartialState {
	readonly [USER_FEATURE_KEY]: State;
}

export const initialState: State = {
	expires: undefined,
	ped: undefined,
	pids: undefined,
	defaultPid: undefined,
	username: undefined,
	permissions: [],
	uan: undefined,
	loading: false,
	error: undefined
};

const userReducer = createReducer(
	initialState,

	on(UserActions.Loading, (state, {loading}) => ({...state, loading, error: undefined})),

	on(UserActions.Login, (state) => ({...state, loading: true, error: undefined})),
	on(UserActions.LoginComplete, UserActions.LoginMFAComplete, UserActions.RefreshTokenComplete,
		(state, {payload: {expires, ped, pids, username, permissions, uan, loading}}) => ({
			...state, expires, ped, pids, username, permissions, uan, loading, error: undefined
		})),
	on(UserActions.LoginFailed, (state, {error}) => ({...state, loading: false, error})),
	on(UserActions.MultiFactorAuthUpdateComplete, (state, {payload}) => ({...state, loading: false, mfa: payload.mfaActive})),

	on(UserActions.SetDefaultPid, (state, {defaultPid}) => ({...state, defaultPid})),

	on(UserActions.Error, (state, {error}) => ({...state, error})),
	on(UserActions.Logout, UserActions.ClearState, () => ({...initialState}))
);

export function reducer(state: State | undefined, action: Action) {
	return userReducer(state, action);
}
