import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { Injector } from '@angular/core';
import { LOCATION_INITIALIZED } from '@angular/common';
import { MissingTranslationHandler, MissingTranslationHandlerParams, TranslateService } from '@ngx-translate/core';
import { DEFAULT_LANGUAGE, SupportedLanguages } from './translations.enum';
import { EnvironmentUtils } from '@ap-angular/shared/utils';

export function HttpLoaderFactory(http: HttpClient, apiPath?: string) {
	const API_PATH = apiPath ? `${apiPath}/` : '';
	return new TranslateHttpLoader(http,
		`${EnvironmentUtils.getWidgetEnvironmentDomain()}/assets/i18n/${API_PATH}`,
		`.json?v=${EnvironmentUtils.getRawAppVersion()}`
	);
}

export function InitTranslateFactory(
	translate: TranslateService,
	//applicationService: ApplicationService,
	injector: Injector
) {
	return () => new Promise(resolve => {
		injector.get(LOCATION_INITIALIZED, Promise.resolve(null)).then(() => {
			translate.setDefaultLang(DEFAULT_LANGUAGE);
			const clientLang: SupportedLanguages = DEFAULT_LANGUAGE;
			//valueExistsInEnum(SupportedLanguages, translate.getBrowserLang()) ?
			//(translate.getBrowserLang() as SupportedLanguages) : DEFAULT_LANGUAGE;

			translate.use(clientLang).subscribe(() => {
				//applicationService.setAppLanguage(clientLang);
			}, error => {
				console.error(`Error: Issues with language initialization.`, error);
				window.location.reload();
			}, () => {
				resolve(null);
			});
		});
	});
}

export class GlobalMissingTranslationHandler implements MissingTranslationHandler {
	handle(params: MissingTranslationHandlerParams) {
		console.warn(`%cMissing translation key: ${params.key}`, 'color: Red; font-weight: bold');
		// return `NO TRANSLATION -> ${params.key}`;
		return '';
	}
}
