<form *ngIf="data" [ngClass]="data?.class ? data.class : ''">
    <button mat-button color="primary" class="close" mat-dialog-close *ngIf="data && !data.closeIcon">
        <mat-icon class="icon-close" color="primary"></mat-icon>
    </button>
    <h1 mat-dialog-title>{{ 'Pages.Provider.History.Title' | translate }}</h1>
    <mat-dialog-content>
        <div class="table-wrapper">
            <ng-container *ngIf="personHistoryList && personHistoryList.length > 0; else empty">
                <mat-table [dataSource]="personHistoryList">
                    <ng-container matColumnDef="RevisionDate" sticky="true">
                        <mat-header-cell class="date" *matHeaderCellDef>{{'Pages.Provider.History.Revision_Date' | translate}}</mat-header-cell>
                        <mat-cell class="strong date" *matCellDef="let person; let i = index">
                            <span>{{ person.revisionDate | date : 'dd.MM.yyyy HH:mm'}}</span>
                        </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="ModifiedBy">
                        <mat-header-cell *matHeaderCellDef>{{'Pages.Provider.History.Modified_By' | translate}}</mat-header-cell>
                        <mat-cell class="strong" *matCellDef="let person; let i = index">
                            <span>{{ person.modifiedBy }}</span>
                        </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="RevisionType">
                        <mat-header-cell *matHeaderCellDef>{{'Pages.Provider.History.Revision_Type' | translate}}</mat-header-cell>
                        <mat-cell class="strong" *matCellDef="let person; let i = index">
                            <span>{{ getRevisionType(person.revisionType) | translate}}</span>
                        </mat-cell>
                    </ng-container>

                    <ng-container [ngSwitch]="data.historyType">



                        <ng-container *ngSwitchCase="HistoryTypeEnumDTO.PERSON">
                            <ng-container matColumnDef="Hidden">
                                <mat-header-cell *matHeaderCellDef>{{'Modal.Provider.History.Profile_Visible' | translate}}</mat-header-cell>
                                <mat-cell class="strong" *matCellDef="let person; let i = index">
                                    <span>{{ (person.hidden ? 'App.General.No' : 'App.General.Yes') | translate }}</span>
                                </mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="Salutation">
                                <mat-header-cell *matHeaderCellDef>{{'App.General.Salutation' | translate}}</mat-header-cell>
                                <mat-cell class="strong" *matCellDef="let person; let i = index">
                                    <span>{{ person.salutation }}</span>
                                </mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="Title">
                                <mat-header-cell *matHeaderCellDef>{{'App.General.Title' | translate}}</mat-header-cell>
                                <mat-cell class="strong" *matCellDef="let person; let i = index">
                                    <span>{{ person.academicalDegree }}</span>
                                </mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="Firstname">
                                <mat-header-cell *matHeaderCellDef>{{'App.General.Firstname' | translate}}</mat-header-cell>
                                <mat-cell class="strong" *matCellDef="let person; let i = index">
                                    <span>{{person.firstname}}</span>
                                </mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="Lastname">
                                <mat-header-cell *matHeaderCellDef>{{'App.General.Lastname' | translate}}</mat-header-cell>
                                <mat-cell class="strong" *matCellDef="let person; let i = index">
                                    <span>{{person.lastname}}</span>
                                </mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="Email">
                                <mat-header-cell *matHeaderCellDef>{{'App.General.Email' | translate}}</mat-header-cell>
                                <mat-cell class="strong" *matCellDef="let person; let i = index">
                                    <span>{{person.email}}</span>
                                </mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="Telefon">
                                <mat-header-cell *matHeaderCellDef>{{'App.General.Phone' | translate}}</mat-header-cell>
                                <mat-cell class="strong" *matCellDef="let person; let i = index">
                                    <span>{{person.telephone}}</span>
                                </mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="Fax">
                                <mat-header-cell *matHeaderCellDef>{{'App.General.Fax' | translate}}</mat-header-cell>
                                <mat-cell class="strong" *matCellDef="let person; let i = index">
                                    <span>{{person.fax}}</span>
                                </mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="Street">
                                <mat-header-cell *matHeaderCellDef>{{'App.General.Street' | translate}}</mat-header-cell>
                                <mat-cell class="strong" *matCellDef="let person; let i = index">
                                    <span>{{person.street}}</span>
                                </mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="HouseNumber">
                                <mat-header-cell *matHeaderCellDef>{{'App.General.HouseNo' | translate}}</mat-header-cell>
                                <mat-cell class="strong" *matCellDef="let person; let i = index">
                                    <span>{{person.houseNumber}}</span>
                                </mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="PostalCode">
                                <mat-header-cell *matHeaderCellDef>{{'App.General.Postcode' | translate}}</mat-header-cell>
                                <mat-cell class="strong" *matCellDef="let person; let i = index">
                                    <span>{{person.postalCode}}</span>
                                </mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="City">
                                <mat-header-cell *matHeaderCellDef>{{'App.General.City' | translate}}</mat-header-cell>
                                <mat-cell class="strong" *matCellDef="let person; let i = index">
                                    <span>{{person.city}}</span>
                                </mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="Homepage">
                                <mat-header-cell *matHeaderCellDef>{{'App.General.Homepage' | translate}}</mat-header-cell>
                                <mat-cell class="strong" *matCellDef="let person; let i = index">
                                    <span>{{person.url}}</span>
                                </mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="AccountStatus">
                                <mat-header-cell *matHeaderCellDef>{{'Pages.Provider.History.Account_Status' | translate}}</mat-header-cell>
                                <mat-cell class="strong" *matCellDef="let person; let i = index">
                                    <span>{{ (person.deleted ? 'Pages.Provider.History.Deleted' : '') | translate }}</span>
                                </mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="PracticeName">
                                <mat-header-cell *matHeaderCellDef>{{'App.General.Practice_Name' | translate}}</mat-header-cell>
                                <mat-cell class="strong" *matCellDef="let person; let i = index">
                                    <span>{{person.practiceName}}</span>
                                </mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="NoventiCustomerNumber">
                                <mat-header-cell *matHeaderCellDef>{{'App.General.Med_Day_Custom_Number' | translate}}</mat-header-cell>
                                <mat-cell class="strong" *matCellDef="let person; let i = index">
                                    <span>{{person.noventiCustomerNumber}}</span>
                                </mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="PublicBillingType">
                                <mat-header-cell *matHeaderCellDef>{{'Pages.Provider.Extra_Info.Payment.Title_Public' | translate}}</mat-header-cell>
                                <mat-cell class="strong" *matCellDef="let person; let i = index">
                                    <span>{{ (person.publicBillingType ? 'App.General.Yes' : 'App.General.No') | translate }}</span>
                                </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="PrivateBillingType">
                                <mat-header-cell *matHeaderCellDef>{{'Pages.Provider.Extra_Info.Payment.Title_Private' | translate}}</mat-header-cell>
                                <mat-cell class="strong" *matCellDef="let person; let i = index">
                                    <span>{{ (person.privateBillingType ? 'App.General.Yes' : 'App.General.No') | translate }}</span>
                                </mat-cell>
                            </ng-container>
                        </ng-container>



                        <ng-container *ngSwitchCase="HistoryTypeEnumDTO.OPENHOURS">
                            <ng-container matColumnDef="Day">
                                <mat-header-cell *matHeaderCellDef>{{ 'App.General.Day' | translate }}</mat-header-cell>
                                <mat-cell class="strong" *matCellDef="let history; let i = index">
                                    <span>{{ 'App.General.Weekday.' + history.day | translate }}</span>
                                </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="Type">
                                <mat-header-cell *matHeaderCellDef>{{ 'Pages.Provider.Schedule.Tab' | translate }} </mat-header-cell>
                                <mat-cell class="strong" *matCellDef="let history; let i = index">
                                    <span>{{ 'App.General.' + history.type | translate }}</span>
                                </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="From">
                                <mat-header-cell *matHeaderCellDef>{{ 'Modal.Provider.Calendar.Start' | translate }}</mat-header-cell>
                                <mat-cell class="strong" *matCellDef="let history; let i = index">
                                    <span>{{ history.from }}</span>
                                </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="To">
                                <mat-header-cell *matHeaderCellDef>{{ 'Modal.Provider.Calendar.End' | translate }}</mat-header-cell>
                                <mat-cell class="strong" *matCellDef="let history; let i = index">
                                    <span>{{ history.to }}</span>
                                </mat-cell>
                            </ng-container>
                        </ng-container>



                        <ng-container *ngSwitchCase="HistoryTypeEnumDTO.LANGUAGE">
                            <ng-container matColumnDef="ExtraInfo">
                                <mat-header-cell *matHeaderCellDef>{{'Pages.Provider.Extra_Info.Label' | translate}}</mat-header-cell>
                                <mat-cell class="strong" *matCellDef="let person; let i = index">
                                    <span>{{ person.medical_fields_list }}</span>
                                </mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="Languages">
                                <mat-header-cell *matHeaderCellDef>Languages</mat-header-cell>
                                <mat-cell class="strong" *matCellDef="let person; let i = index">
                                    <span>{{ person.languages_list }}</span>
                                </mat-cell>
                            </ng-container>
                        </ng-container>



                        <ng-container *ngSwitchCase="HistoryTypeEnumDTO.RESOURCES">
                            <ng-container matColumnDef="LastName">
                                <mat-header-cell *matHeaderCellDef>{{ 'App.General.Lastname' | translate }}</mat-header-cell>
                                <mat-cell class="strong" *matCellDef="let history; let i = index">
                                    <span>{{ history.lastName }}</span>
                                </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="Type">
                                <mat-header-cell *matHeaderCellDef>{{ 'Pages.Provider.History.Type' | translate }}</mat-header-cell>
                                <mat-cell class="strong" *matCellDef="let history; let i = index">
                                    <span>{{'Pages.Provider.Calendar.Resource_Type.' + history.type | translate}}</span>
                                </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="Priority">
                                <mat-header-cell *matHeaderCellDef>{{ 'Pages.Provider.Resource.Priority' | translate }}</mat-header-cell>
                                <mat-cell class="strong" *matCellDef="let history; let i = index">
                                    <span>{{ history.priority }}</span>
                                </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="Color">
                                <mat-header-cell *matHeaderCellDef>{{ 'App.General.Color' | translate }}</mat-header-cell>
                                <mat-cell class="strong" *matCellDef="let history; let i = index">
                                    <span>{{ history.color }}</span>
                                </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="DefaultResource">
                                <mat-header-cell *matHeaderCellDef>{{ 'Pages.Provider.Resource.Default_Resource' | translate }}</mat-header-cell>
                                <mat-cell class="strong" *matCellDef="let history; let i = index">
                                    <span>{{ 'App.General.' + history.defaultResource | translate }}</span>
                                </mat-cell>
                            </ng-container>
                        </ng-container>



                        <ng-container *ngIf="data.historyType === HistoryTypeEnumDTO.MESSAGETEMPLATE">
                            <ng-container matColumnDef="Category">
                                <mat-header-cell *matHeaderCellDef>{{ 'Pages.Provider.History.Category' | translate }}</mat-header-cell>
                                <mat-cell class="strong" *matCellDef="let history; let i = index">
                                    <span>{{ history.label }}</span>
                                </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="Type">
                                <mat-header-cell *matHeaderCellDef>{{ 'Pages.Provider.History.Type' | translate }}</mat-header-cell>
                                <mat-cell class="strong" *matCellDef="let history; let i = index">
                                    <span>{{ history.type }}</span>
                                </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="Content">
                                <mat-header-cell *matHeaderCellDef>{{ 'Pages.Provider.History.Content' | translate }}</mat-header-cell>
                                <mat-cell class="strong" *matCellDef="let history; let i = index">
                                    <span>{{ history.content }}</span>
                                </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="Active">
                                <mat-header-cell *matHeaderCellDef>{{ "App.General.Active" | translate }}</mat-header-cell>
                                <mat-cell class="strong" *matCellDef="let history; let i = index">
                                    <span>{{ 'App.General.' + history.active | translate }}</span>
                                </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="SystemTemplate">
                                <mat-header-cell *matHeaderCellDef>{{ 'Pages.Provider.History.Standard' | translate }}</mat-header-cell>
                                <mat-cell class="strong" *matCellDef="let history; let i = index">
                                    <span>{{ 'App.General.' + history.systemTemplate | translate }}</span>
                                </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="Attachments">
                                <mat-header-cell *matHeaderCellDef>{{ 'Pages.Provider.History.Email.Attachment' | translate }}</mat-header-cell>
                                <mat-cell class="strong" *matCellDef="let history; let i = index">
                                    <span>{{ history.attachments }}</span>
                                </mat-cell>
                            </ng-container>
                        </ng-container>


                    </ng-container>



                    <ng-container *ngIf="data.historyType === HistoryTypeEnumDTO.MEDICALSERVICE
                    || data.historyType === HistoryTypeEnumDTO.EXPERTISEAREA
                    || data.historyType === HistoryTypeEnumDTO.PROFESSION
                    || data.historyType === HistoryTypeEnumDTO.LANGUAGE">
                        <ng-container matColumnDef="Name">
                            <mat-header-cell *matHeaderCellDef>{{ 'App.General.Name' | translate }}</mat-header-cell>
                            <mat-cell class="strong" *matCellDef="let history; let i = index">
                                <span>{{ history.name }}</span>
                            </mat-cell>
                        </ng-container>
                    </ng-container>

                    <mat-header-row *matHeaderRowDef="getTableColumns()" sticky></mat-header-row>
<!--                    let toggle = false;-->
                    <mat-row *matRowDef="let row; let i = index;  columns: getTableColumns()">
<!--                             [ngClass]="{'show-text' : toggle}"-->
<!--                             (click)="toggle = !toggle">-->
                    </mat-row>

                </mat-table>
            </ng-container>

            <ap-angular-loader *ngIf="loading" class="form-loader blur-below"></ap-angular-loader>

            <ng-template #empty>
                <div class="empty center padding" *ngIf="!loading">
                    <div class="text icon-search">{{ 'App.General.NoResultsFound' | translate}}</div>
                </div>
            </ng-template>
        </div>
    </mat-dialog-content>

    <mat-dialog-actions class="flex-space-between">
        <mat-paginator *ngIf="pageablePersonHistory?.totalCount > 25"
                       [disabled]="loading"
                       [pageSizeOptions]="[25, 50, 100]"
                       [length]="pageablePersonHistory?.totalCount"
                       [pageIndex]="pageIndex"
                       [pageSize]="pageEvent.pageSize"
                       [showFirstLastButtons]="true"
                       (page)="pageEvent = $event; loadProviderHistory()">
        </mat-paginator>

        <div class="element-spacer"></div>

        <button mat-button type="button" mat-dialog-close>{{ 'App.General.Close' | translate | uppercase }} </button>
    </mat-dialog-actions>
</form>
