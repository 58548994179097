<mat-sidenav-container>
    <mat-sidenav #sidenav  mode="over" color="primary">
        <ng-container [ngTemplateOutlet]="menu"></ng-container>
    </mat-sidenav>

    <!--    <mat-sidenav position="end">End</mat-sidenav>-->

    <mat-sidenav-content>
        <ng-container [ngTemplateOutlet]="header"></ng-container>
        <ng-content select="[template-loading]"></ng-content>
        <ng-content select="[template-content]"></ng-content>

        <router-outlet><!-- Here goes the magic navigation --></router-outlet>

        <ng-container [ngTemplateOutlet]="footer"></ng-container>
    </mat-sidenav-content>
</mat-sidenav-container>

<ng-template #menu>
    <mat-toolbar class="no-padding sidenav-menu">
        <div class="menu-logo-container">
            <img class="menu-logo" src="assets/medDay-logo-white-long.svg" alt="Med-Day" />
        </div>
    </mat-toolbar>

    <mat-nav-list class="no-padding margin-bottom" (click)="sidenav.toggle()" *ngIf="(permission$ | async) as permission">
        <a mat-list-item routerLinkActive="active-list-item" *ngIf="permission.includes('PROFILE_DATA_VIEW')"
           [routerLink]="PAGE.ADMIN_PROVIDER.fullpath" (click)="sidenav.toggle()">  
            <span class="mat-list-text ">{{'Pages.Admin.Provider.Label' | translate}}</span>
        </a>
        <a mat-list-item routerLinkActive="active-list-item" *ngIf="permission.includes('USERS_MANAGEMENT_VIEW')"
           [routerLink]="PAGE.ADMIN_USER.fullpath" (click)="sidenav.toggle()">
            <span class="mat-list-text ">{{'Pages.Admin.User.Label' | translate}}</span>
        </a>
        <a mat-list-item routerLinkActive="active-list-item" *ngIf="permission.includes('CMS_VIEW')"
           [routerLink]="PAGE.ADMIN_CMS.fullpath" (click)="sidenav.toggle()">   
            <span class="mat-list-text ">{{'Pages.Admin.Cms.Label' | translate}}</span>
        </a>
        <a mat-list-item routerLinkActive="active-list-item" *ngIf="permission.includes('PROFILE_MANAGEMENT_VIEW')"
           [routerLink]="PAGE.ADMIN_GENERAL.fullpath" (click)="sidenav.toggle()">
            <span class="mat-list-text ">{{'Pages.Admin.GeneralData.Title' | translate}}</span>
        </a>
    </mat-nav-list>

    <div class="element-spacer"></div>

    <mat-hint *ngIf="!env.production" class="version margin">v {{env.version}}</mat-hint>
</ng-template>

<ng-template #header>
    <mat-toolbar class="template-header template-header--blue">
        <button mat-icon-button class="hide-on-extraLargeVar-and-up show-on-largeVar-and-down" (click)="sidenav.toggle()">
            <mat-icon>menu</mat-icon>
        </button>

        <div class="header-logo-container">
            <img class="header-logo " src="assets/med-day-logo-small.svg" alt="Med-Day" />
        </div>

        <ng-content header-content select="[template-header]"></ng-content>

        <div class="top-bar-action-buttons-container hide-on-largeVar-and-down" *ngIf="(permission$ | async) as permission">
            <a   class="top-bar-action-button"  routerLinkActive="top-bar-action-button--active" *ngIf="permission.includes('PROFILE_DATA_VIEW')"
            [routerLink]="PAGE.ADMIN_PROVIDER.fullpath">  
                <span>{{'Pages.Admin.Provider.Label' | translate}}</span>
            </a>
            <a   class="top-bar-action-button"  routerLinkActive="top-bar-action-button--active" *ngIf="permission.includes('USERS_MANAGEMENT_VIEW')"
            [routerLink]="PAGE.ADMIN_USER.fullpath">
                <span>{{'Pages.Admin.User.Label' | translate}}</span>
            </a>
            <a  class="top-bar-action-button"   routerLinkActive="top-bar-action-button--active" *ngIf="permission.includes('CMS_VIEW')"
            [routerLink]="PAGE.ADMIN_CMS.fullpath">   
                <span>{{'Pages.Admin.Cms.Label' | translate}}</span>
            </a>
            <a  class="top-bar-action-button"  routerLinkActive="top-bar-action-button--active" *ngIf="permission.includes('PROFILE_MANAGEMENT_VIEW')"
            [routerLink]="PAGE.ADMIN_GENERAL.fullpath">
                <span>{{'Pages.Admin.GeneralData.Title' | translate}}</span>
            </a>
        </div>

        

        <button #headerUser class="header-user" mat-button [matMenuTriggerFor]="appMenu">
            <div class="header-user-content">
                <mat-icon>account_circle</mat-icon>
                <div class="margin-left-S text-trim show-on-extraLargeVar-and-up hide-on-largeVar-and-down">{{ (name$ | async) }}</div>
                <mat-icon>keyboard_arrow_down</mat-icon>
            </div>
        </button>
    </mat-toolbar>
</ng-template>

<ng-template #footer>
    <!--    <mat-toolbar class="template-footer" color="transparent">-->
    <ng-content footer-content select="[template-footer]"></ng-content>
    <!--    </mat-toolbar>-->
</ng-template>

<mat-menu #appMenu="matMenu">
    <button mat-menu-item color="primary" type="button" [routerLink]="PAGE.ADMIN_SETTING.fullpath">
        {{'Pages.Provider.User.Settings.Title' | translate}}
    </button>

    <button mat-menu-item color="primary" (click)="logout()">
        {{'Pages.Logout.Label' | translate}}
    </button>
</mat-menu>
