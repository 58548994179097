import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';

@Component({
	selector: 'ap-angular-logo',
	styles: [`			
			:host img {
					width:100%;
					height: auto;
					margin: 20px 42px 10px 23px;
        }
	`],
	templateUrl: './logo.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.Emulated
})
export class LogoComponent {
}
