/**
 * Appointment Planner - Emailing REST API
 * Appointment Planner Emailing Service REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: valeriu.craciun@kronsoft.ro
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type ContactCategoryEnumDTO = 'Bestellung / Zugang' | 'Fragen / Informationen';

export const ContactCategoryEnumDTO = {
    ORDER: 'Bestellung / Zugang' as ContactCategoryEnumDTO,
    INFO: 'Fragen / Informationen' as ContactCategoryEnumDTO
};

