<form *ngIf="data" [formGroup]="form">
    <button mat-button color="primary" class="close" mat-dialog-close *ngIf="data && !data.closeIcon">
        <mat-icon class="icon-close" color="primary"></mat-icon>
    </button>
    <h1 mat-dialog-title>{{dialogTitle()}}</h1>
    <mat-dialog-content>
        {{data.content}}

        <div class="row" margin-vertical>
            <mat-form-field appearance="outline" class="col s12 m6" no-padding>
                <mat-label>{{(data.pattern === ("Modal.Archive.Provider.Pattern" | translate) ? "App.General.Enter" : "App.General.Type_In") | translate}} "{{data.pattern}}"</mat-label>
                <input matInput formControlName="confirmation">
                <mat-error>{{'Error.Pattern_Match_String' | translate : {pattern: data.pattern} }}</mat-error>
            </mat-form-field>
        </div>
    </mat-dialog-content>
    <mat-dialog-actions [attr.align]="'right'">
        <button mat-stroked-button color="primary" type="button" [mat-dialog-close]="true" [attr.cdkFocusInitial]="true"
                (click)="dialogRef.close(false)">
            {{ 'App.General.Cancel' | translate }}
        </button>
        <button mat-flat-button color="primary" type="button" [mat-dialog-close]="true" [attr.cdkFocusInitial]="false"
                [disabled]="form.invalid || form.pristine"
                (click)="dialogRef.close(true)">
            {{ data.confirmButton ? data.confirmButton : ('App.General.Delete' | translate) }}
        </button>
    </mat-dialog-actions>
</form>
