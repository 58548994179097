<button mat-button type="button" color="primary" class="close" mat-dialog-close *ngIf="data && !data.closeIcon">
    <mat-icon class="icon-close" color="primary"></mat-icon>
</button>
<h1 mat-dialog-title>{{'Modal.Provider.Widget.Title' | translate}}</h1>

<mat-dialog-content>
    <!-- 181 - Removed section related to portal in widget inclusion advices screen -->
    <p class="padding-top">{{'Modal.Provider.Widget.Content_Block_Script' | translate}}:</p>
    <div class="html-code flex relative margin-bottom">
        <mat-button-toggle-group>
            <mat-button-toggle color="primary" [checked]="widget" (click)="widget = true">
                <mat-icon>widgets</mat-icon>
            </mat-button-toggle>
            <mat-button-toggle color="primary" [checked]="!widget" (click)="widget = false">
                <mat-icon>code</mat-icon>
            </mat-button-toggle>
        </mat-button-toggle-group>
        <div [innerHTML]="displayWidgetCode(widget)"></div>
        <button mat-icon-button type="button" color="primary" matTooltipPosition="before"
                [matTooltip]="'App.General.Copy_Script' | translate"
                (click)="copy(widget ? 'widget' : 'iframe')">
            <mat-icon>content_copy</mat-icon>
        </button>
    </div>
</mat-dialog-content>

<mat-dialog-actions [attr.align]="'right'">
    <button mat-stroked-button type="button" (click)="close()" color="primary">{{'App.General.Close' | translate }}</button>
</mat-dialog-actions>
