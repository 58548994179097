/**
 * Appointment Planner - Care Providers REST API
 * Appointment Planner Care Providers Service REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: valeriu.craciun@kronsoft.ro
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Type of hisotry
 */
export type HistoryTypeEnumDTO = 'PERSON' | 'OPEN_HOURS' | 'RESOURCES' | 'EXPERTISE_AREA' | 'MESSAGE_TEMPLATE' | 'MEDICAL_SERVICE' | 'LANGUAGE' | 'PROFESSION';

export const HistoryTypeEnumDTO = {
    PERSON: 'PERSON' as HistoryTypeEnumDTO,
    OPENHOURS: 'OPEN_HOURS' as HistoryTypeEnumDTO,
    RESOURCES: 'RESOURCES' as HistoryTypeEnumDTO,
    EXPERTISEAREA: 'EXPERTISE_AREA' as HistoryTypeEnumDTO,
    MESSAGETEMPLATE: 'MESSAGE_TEMPLATE' as HistoryTypeEnumDTO,
    MEDICALSERVICE: 'MEDICAL_SERVICE' as HistoryTypeEnumDTO,
    LANGUAGE: 'LANGUAGE' as HistoryTypeEnumDTO,
    PROFESSION: 'PROFESSION' as HistoryTypeEnumDTO
};

