import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { ModalUtils } from '@ap-angular/shared/utils';
import { DialogContentComponent } from './content/content.component';

@Injectable()
export class DialogService {

	constructor(
		private matDialog: MatDialog,
		private translateService: TranslateService
	) {
	}

	private _extractInvalidControls(formGroup: FormGroup, list: any) {
		const form = formGroup;
		let invalidLabels;
		Object.keys(form.controls).forEach((item) => {
			if (form.controls[item].invalid === true) {
				invalidLabels = !!invalidLabels ? invalidLabels + ', ' : '';
				let translation = '';

				if (!list[item] && !(form.get(item) as FormGroup).controls) {
					invalidLabels = invalidLabels.slice(0, -2); // Remove ', '
					console.warn(`%cMissing translation key: ${item}`, 'color: Red; font-weight: bold');
				} else {
					translation = this.translateService.instant(list[item]);
					invalidLabels = invalidLabels + translation;
				}

				form.get(item).markAsDirty();
				form.get(item).markAsTouched();
			}
		});

		return invalidLabels ? invalidLabels : '';
	}

	public openDialog(component: any, config?: MatDialogConfig): MatDialogRef<any> {
		return this.matDialog.open(component, config);
	}

	public showInvalidControls(form: FormGroup, list: any, config?: MatDialogConfig): MatDialogRef<any> {
		let title: any = {default: this.translateService.instant('Modal.Error_No_Changes.Title.Default')};
		let content: string = this.translateService.instant('Modal.Error_No_Changes.Text');

		if (!!form) {
			const invalidControls: string = this._extractInvalidControls(form, list);
			if (invalidControls.length > 0) {
				title = {
					default: this.translateService.instant('Modal.Error_Invalid_Controls.Title.Default'),
					s: this.translateService.instant('Modal.Error_Invalid_Controls.Title.Small')
				};
				content = this.translateService.instant('Modal.Error_Invalid_Controls.Text') +
						  invalidControls;
			}
		}

		const configDefault: MatDialogConfig = {
			panelClass: 'invalid-controls',
			disableClose: false,
			closeOnNavigation: false,
			data: {
				title: title,
				content: content,
				actions: [{
					title: 'Ok',
					closeButton: true
				}],
				actionsConfig: {
					align: 'right'
				},
				disableClose: true
			}
		};

		config = config ? Object.assign({}, configDefault, config) : configDefault;

		return this.matDialog.open(DialogContentComponent, config);
	}

	/**
	 * Provider
	 */
	public configProviderDelete(isProvider: boolean): MatDialogConfig {
		const config = ModalUtils.config();
		config.data.title = {
			default: isProvider ?
				this.translateService.instant('Modal.Provider.Delete.Title.Default.Provider') :
				this.translateService.instant('Modal.Provider.Delete_Title_Default'),
			s: this.translateService.instant('Modal.Provider.Delete.Title.Small')
		};
		config.data.content = isProvider ?
			this.translateService.instant('Modal.Provider.Delete.Text.Provider') :
			this.translateService.instant('Modal.Provider.Delete.Text');
		config.data.pattern = 'LÖSCHEN';
		return config;
	}

	/**
	 * Provider - calendar
	 */
	public configCalendarWidgetPeriodDelete(event: any): MatDialogConfig {
		const config = ModalUtils.config();
		config.data.title = {
			default: this.translateService.instant('Modal.Provider.Calendar.Title_Delete')
		};
		config.data.event = event;
		config.data.content = this.translateService.instant('App.General.Online_Termine_Delete_Content');
		config.data.actions = [{
			title: this.translateService.instant('App.General.Cancel'),
			color: 'primary',
			closeButton: true
		}, {
			title: this.translateService.instant('App.General.Delete'),
			color: 'primary',
			extraClass: 'mat-flat-button',
			closeButton: false
		}];
		config.data.actionsConfig = {
			align: 'space-between'
		};
		return config;
	}

	public configCalendarWidgetPeriodDeleteInfo(error: any, html: string): MatDialogConfig {
		const config = ModalUtils.config();
		config.data.title = {
			default: this.translateService.instant('Modal.Provider.Calendar.Title_Conflict')
		};
		config.data.content = html;
		config.data.conflicts = error.error.additionalFields;
		config.data.actions = [{
			title: this.translateService.instant('App.General.Ok'),
			color: 'primary',
			extraClass: 'mat-flat-button',
			closeButton: false
		}];
		return config;
	}


	public configWarningContactInfo(): MatDialogConfig {
		const config: MatDialogConfig = ModalUtils.config();
		config.closeOnNavigation = false;
		config.width = '500px';
		config.data = {
			title: {
				default: this.translateService.instant('Modal.Confirm_Save_WithoutContactInfo.Title'),
			},
			icon: 'warning-icon',
			content: this.translateService.instant('Modal.Confirm_Save_WithoutContactInfo.Content'),
			actions: [
				{
					title: this.translateService.instant('Modal.Confirm_Save_WithoutContactInfo.Confirm'),
					color: 'primary'
				},
				{
					title: this.translateService.instant('Modal.Confirm_Save_WithoutContactInfo.Cancel'),
					color: 'primary',
					extraClass: 'mat-flat-button',
					closeButton: true,
				}
			]
		};
		config.data.actionsConfig = {align: 'space-between'};
		return config;
	}
	

	/**
	 * Confirmation Modal
	 */
	public configWarningMessage(action: string): MatDialogConfig {
		const config = ModalUtils.config();
		config.data.title = {
			default: this.translateService.instant('Modal.Confirm_' + action + '_Title')
		};
		config.data.content = this.translateService.instant('Modal.Confirm_' + action + '_Content');
		config.data.actions = [
			{
				title: this.translateService.instant('App.General.' + action + '.CANCEL'),
				color: 'primary',
				closeButton: true
			},
			{
				title: this.translateService.instant('App.General.' + action + '.CONFIRM'),
				color: 'primary',
				extraClass: 'mat-flat-button',
				closeButton: false
			}
		];
		config.data.actionsConfig = {align: 'space-between'};
		return config;
	}

	public configWarningMessageExtended(extend: { type: string, name?: string, number?: number }): MatDialogConfig {
		const config = this.configWarningMessage(extend.type);
		if (extend.type === 'Delete') {
			config.data.content = this.translateService.instant('Modal.Confirm_Delete_Content',
				{name: extend.name, number: extend.number});
		}
		return config;
	}

	public configModalWithForm(): MatDialogConfig {
		const config = ModalUtils.config();
		config.disableClose = true;
		return config;
	}
}
