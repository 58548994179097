import { HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { filter, switchMap, take } from 'rxjs/operators';
import { RequestOptions } from './request-options';
import { HttpInterceptor } from './http-interceptor';
import { UserService } from '@ap-angular/service/user';
import { isNullOrUndefined } from '@ap-angular/shared/utils';


@Injectable()
export class HttpAuthInterceptor extends HttpInterceptor {

	private _isRefreshingToken = new BehaviorSubject(false);

	private _lastRefreshError = undefined;

	constructor(
		@Inject('ENVIRONMENT') private _environment: any,
		private router: Router,
		private userService: UserService
	) {
		super();
	}

	public interceptRequestOptions(options?): RequestOptions {
		if (options === null || options === undefined) {
			options = new RequestOptions();
		}
		if (options.headers === null || options.headers === undefined) {
			options.headers = new HttpHeaders();
		}
		/** Disabled withCredentials for widget */
		options.withCredentials = !(this._environment && this._environment.app === 'widget');

		return options;
	}

	public refreshTokenIfNeeded$(): Observable<boolean> {
		const hasRefreshExpirationTime = !isNullOrUndefined(this._state);
		if (!hasRefreshExpirationTime) {
			this._isRefreshingToken.next(false);
			return of(false);
		}

		const isRefreshingToken = this._isRefreshingToken.getValue();
		if (isRefreshingToken !== true && this._shouldRefreshToken) {
			this.userService.refreshToken$()
				.pipe(
					take(1),
				)
				.subscribe(
					() => this._isRefreshingToken.next(false),
					() => this.router.navigate(['./']).then()
				);
		} else if (isRefreshingToken === undefined) {
			return throwError(this._lastRefreshError);
		}

		return this._isRefreshingToken.pipe(
			filter(r => !r),
			take(1),
			switchMap(r => r === undefined ? throwError(this._lastRefreshError) : of(r))
		);
	}

	private get _shouldRefreshToken(): boolean {
		const NOW = new Date();
		const EXPIRE = new Date(this._state);
		/** Refresh token one minute before expiration */
		NOW.setTime(NOW.getTime() + 60000);
		return EXPIRE < NOW;
	}

	private get _state(): string {
		const STATE = JSON.parse(localStorage.getItem('user'));
		return (STATE && STATE.expires) ? STATE.expires : undefined;
	}
}
