<mat-toolbar class="flex-space-between" color="light">
    <button mat-icon-button [routerLink]="PAGE.ADMIN_PROVIDER.fullpath" class="margin-right">
        <mat-icon>keyboard_backspace</mat-icon>
    </button>

    <ng-container *ngIf="(provider$ | async) as provider; else create">
        <h1 class="component-title text-trim">
            {{ProviderUtils.displayName(provider, true)}}
            <span mat-line class="block small">{{provider?.id ? 'ID: ' + provider.id : provider.email}}</span>
        </h1>
    </ng-container>
    <ng-template #create>{{'App.General.Create' | translate}} {{'Pages.Provider.Title' | translate}}</ng-template>

    <div class="element-spacer"></div>

    <ng-container *ngIf="(provider$ | async) as provider">
        <ap-provider-profile-status [provider]="provider"></ap-provider-profile-status>

        <div *ngIf="provider.externalSource" class="margin-left">
            <div mat-line class="small">{{'Admin.Provider.ExternalSource.' + provider.externalSource | translate}}</div>
        </div>
    </ng-container>

    <button mat-icon-button color="primary" type="button" *ngIf="historyType" matTooltipPosition="before"
            [matTooltip]="'Pages.Provider.History.Title' | translate | uppercase"
            (click)="history()">
        <mat-icon>history</mat-icon>
    </button>
</mat-toolbar>

<nav mat-tab-nav-bar color="light" *ngIf="(permission$ | async) as permission">
    <a mat-tab-link routerLinkActive="active-list-item" *ngIf="permission.includes('PROFILE_ACCESS_VIEW')"
       [routerLink]="url + PAGE.ADMIN_PROVIDER_USER_LIST.path"
       #tab1="routerLinkActive" [active]="tab1.isActive">
        {{'Pages.Provider.Access.Tab' | translate }}
    </a>
    <a mat-tab-link routerLinkActive="active-list-item" *ngIf="permission.includes('PROFILE_DATA_VIEW')"
       [routerLink]="url + PAGE.PROVIDER_INFORMATION.path"
       #tab2="routerLinkActive" [active]="tab2.isActive">
        {{'Api.ProviderProfile.Page_Name.Information' | translate}}
    </a>
    <a mat-tab-link routerLinkActive="active-list-item" *ngIf="permission.includes('OFFICE_HOURS_VIEW')"
       [routerLink]="url + PAGE.PROVIDER_SCHEDULE.path"
       #tab3="routerLinkActive" [active]="tab3.isActive">
        {{'Pages.Provider.Schedule.Label' | translate}}
    </a>
    <a mat-tab-link routerLinkActive="active-list-item" *ngIf="permission.includes('PROFILE_ADDITIONAL_DATA_VIEW')"
       [routerLink]="url + PAGE.PROVIDER_EXTRA_INFO.path"
       #tab6="routerLinkActive" [active]="tab6.isActive">
        {{'Pages.Provider.Extra_Info.Label' | translate}}
    </a>
</nav>
    
<router-outlet></router-outlet>