/**
 * Appointment Planner - Care Providers REST API
 * Appointment Planner Care Providers Service REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: valeriu.craciun@kronsoft.ro
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * The type of treatment category
 */
export type TreatmentCategoryTypeEnumDTO = 'INTERNAL' | 'PUBLIC';

export const TreatmentCategoryTypeEnumDTO = {
    INTERNAL: 'INTERNAL' as TreatmentCategoryTypeEnumDTO,
    PUBLIC: 'PUBLIC' as TreatmentCategoryTypeEnumDTO
};

