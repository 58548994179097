/**
 * Appointment Planner - Online Appointments REST API
 * Appointment Planner Online Appointments Service REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: valeriu.craciun@kronsoft.ro
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

import { AnonymizedAppointmentsResponseDTO } from '../model/models';
import { AppointmentDTO } from '../model/models';
import { AppointmentsCountDTO } from '../model/models';
import { AvailableSlotDTO } from '../model/models';
import { CheckSlotDTO } from '../model/models';
import { FreeSlotsResponseDTO } from '../model/models';
import { PageableAppointmentInfosRequestDTO } from '../model/models';
import { PageableAppointmentInfosResponseDTO } from '../model/models';
import { PatientAppointmentStatisticsDTO } from '../model/models';
import { PatientDetailsDTO } from '../model/models';
import { PatientDetailsInfoDTO } from '../model/models';
import { PublicAppointmentDTO } from '../model/models';
import { ResourceGdprDTO } from '../model/models';
import { SlotItemDTO } from '../model/models';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';



@Injectable({
  providedIn: 'root'
})
export class ClassicAppointmentService {

    protected basePath = 'http://localhost:8515';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }



    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key,
                        (value as Date).toISOString().substr(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * Anonymize past appointments for patients
     * @param patientIds Patient Ids
     * @param date Date to filter appointments by
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public anonymizePastAppointments(patientIds: Array<number>, date: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<AnonymizedAppointmentsResponseDTO>;
    public anonymizePastAppointments(patientIds: Array<number>, date: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<AnonymizedAppointmentsResponseDTO>>;
    public anonymizePastAppointments(patientIds: Array<number>, date: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<AnonymizedAppointmentsResponseDTO>>;
    public anonymizePastAppointments(patientIds: Array<number>, date: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (patientIds === null || patientIds === undefined) {
            throw new Error('Required parameter patientIds was null or undefined when calling anonymizePastAppointments.');
        }
        if (date === null || date === undefined) {
            throw new Error('Required parameter date was null or undefined when calling anonymizePastAppointments.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (patientIds) {
            patientIds.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'patientIds');
            })
        }
        if (date !== undefined && date !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>date, 'date');
        }

        let headers = this.defaultHeaders;

        // authentication (bearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.put<AnonymizedAppointmentsResponseDTO>(`${this.configuration.basePath}/appointments`,
            null,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Assign appointments to merged patient
     * @param careProviderId Current Care Provider Id
     * @param patientId Merged patient id
     * @param oldPatientIds List of patient ids
     * @param patientDetailsDTO Patient details request body
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public assignAppointmentsToMergedPatient(careProviderId: number, patientId: number, oldPatientIds: Array<number>, patientDetailsDTO: PatientDetailsDTO, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<any>;
    public assignAppointmentsToMergedPatient(careProviderId: number, patientId: number, oldPatientIds: Array<number>, patientDetailsDTO: PatientDetailsDTO, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpResponse<any>>;
    public assignAppointmentsToMergedPatient(careProviderId: number, patientId: number, oldPatientIds: Array<number>, patientDetailsDTO: PatientDetailsDTO, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpEvent<any>>;
    public assignAppointmentsToMergedPatient(careProviderId: number, patientId: number, oldPatientIds: Array<number>, patientDetailsDTO: PatientDetailsDTO, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined}): Observable<any> {
        if (careProviderId === null || careProviderId === undefined) {
            throw new Error('Required parameter careProviderId was null or undefined when calling assignAppointmentsToMergedPatient.');
        }
        if (patientId === null || patientId === undefined) {
            throw new Error('Required parameter patientId was null or undefined when calling assignAppointmentsToMergedPatient.');
        }
        if (oldPatientIds === null || oldPatientIds === undefined) {
            throw new Error('Required parameter oldPatientIds was null or undefined when calling assignAppointmentsToMergedPatient.');
        }
        if (patientDetailsDTO === null || patientDetailsDTO === undefined) {
            throw new Error('Required parameter patientDetailsDTO was null or undefined when calling assignAppointmentsToMergedPatient.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (careProviderId !== undefined && careProviderId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>careProviderId, 'careProviderId');
        }
        if (patientId !== undefined && patientId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>patientId, 'patientId');
        }
        if (oldPatientIds) {
            oldPatientIds.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'oldPatientIds');
            })
        }

        let headers = this.defaultHeaders;

        // authentication (bearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<any>(`${this.configuration.basePath}/appointments/merge`,
            patientDetailsDTO,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Check if a duration is open
     * @param allowAppointmentsOverlap Shows whether appointment overlapping is allowed or not
     * @param checkSlotDTO Object containing necessary data for checking if a slot is open
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public checkSlot(allowAppointmentsOverlap?: boolean, checkSlotDTO?: CheckSlotDTO, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<SlotItemDTO>;
    public checkSlot(allowAppointmentsOverlap?: boolean, checkSlotDTO?: CheckSlotDTO, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<SlotItemDTO>>;
    public checkSlot(allowAppointmentsOverlap?: boolean, checkSlotDTO?: CheckSlotDTO, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<SlotItemDTO>>;
    public checkSlot(allowAppointmentsOverlap?: boolean, checkSlotDTO?: CheckSlotDTO, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (allowAppointmentsOverlap !== undefined && allowAppointmentsOverlap !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>allowAppointmentsOverlap, 'allowAppointmentsOverlap');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<SlotItemDTO>(`${this.configuration.basePath}/classic-appointment/check-slot`,
            checkSlotDTO,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Clean appointments after treatment duration
     * @param careProviderId Care provider id
     * @param treatmentId Treatment id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public cleanAppointmentsAfterTreatmentDeletion(careProviderId: number, treatmentId: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<any>;
    public cleanAppointmentsAfterTreatmentDeletion(careProviderId: number, treatmentId: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpResponse<any>>;
    public cleanAppointmentsAfterTreatmentDeletion(careProviderId: number, treatmentId: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpEvent<any>>;
    public cleanAppointmentsAfterTreatmentDeletion(careProviderId: number, treatmentId: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined}): Observable<any> {
        if (careProviderId === null || careProviderId === undefined) {
            throw new Error('Required parameter careProviderId was null or undefined when calling cleanAppointmentsAfterTreatmentDeletion.');
        }
        if (treatmentId === null || treatmentId === undefined) {
            throw new Error('Required parameter treatmentId was null or undefined when calling cleanAppointmentsAfterTreatmentDeletion.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (careProviderId !== undefined && careProviderId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>careProviderId, 'careProviderId');
        }
        if (treatmentId !== undefined && treatmentId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>treatmentId, 'treatmentId');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<any>(`${this.configuration.basePath}/appointments/clean-after-treatment-deletion`,
            null,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Cleanup all appointments and business hours related to resources
     * @param careProviderId Care provider id
     * @param resourceIds Resuource ids
     * @param xSmsSimulate 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public cleanupAppointmentDataForResource(careProviderId: number, resourceIds: Array<number>, xSmsSimulate?: boolean, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<any>;
    public cleanupAppointmentDataForResource(careProviderId: number, resourceIds: Array<number>, xSmsSimulate?: boolean, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpResponse<any>>;
    public cleanupAppointmentDataForResource(careProviderId: number, resourceIds: Array<number>, xSmsSimulate?: boolean, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpEvent<any>>;
    public cleanupAppointmentDataForResource(careProviderId: number, resourceIds: Array<number>, xSmsSimulate?: boolean, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined}): Observable<any> {
        if (careProviderId === null || careProviderId === undefined) {
            throw new Error('Required parameter careProviderId was null or undefined when calling cleanupAppointmentDataForResource.');
        }
        if (resourceIds === null || resourceIds === undefined) {
            throw new Error('Required parameter resourceIds was null or undefined when calling cleanupAppointmentDataForResource.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (careProviderId !== undefined && careProviderId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>careProviderId, 'careProviderId');
        }
        if (resourceIds) {
            resourceIds.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'resourceIds');
            })
        }

        let headers = this.defaultHeaders;
        if (xSmsSimulate !== undefined && xSmsSimulate !== null) {
            headers = headers.set('x-sms-simulate', String(xSmsSimulate));
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.delete<any>(`${this.configuration.basePath}/classic-appointment`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create a new classic appointment. Performed only by Care Provider.
     * @param bookingLink Booking link template
     * @param allowAppointmentOverlap Allow appointment overlap
     * @param appointmentDTO New online appointment request body
     * @param waitingListId Waiting list request id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createAppointment(bookingLink: string, allowAppointmentOverlap: boolean, appointmentDTO: AppointmentDTO, waitingListId?: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<AppointmentDTO>;
    public createAppointment(bookingLink: string, allowAppointmentOverlap: boolean, appointmentDTO: AppointmentDTO, waitingListId?: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<AppointmentDTO>>;
    public createAppointment(bookingLink: string, allowAppointmentOverlap: boolean, appointmentDTO: AppointmentDTO, waitingListId?: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<AppointmentDTO>>;
    public createAppointment(bookingLink: string, allowAppointmentOverlap: boolean, appointmentDTO: AppointmentDTO, waitingListId?: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (bookingLink === null || bookingLink === undefined) {
            throw new Error('Required parameter bookingLink was null or undefined when calling createAppointment.');
        }
        if (allowAppointmentOverlap === null || allowAppointmentOverlap === undefined) {
            throw new Error('Required parameter allowAppointmentOverlap was null or undefined when calling createAppointment.');
        }
        if (appointmentDTO === null || appointmentDTO === undefined) {
            throw new Error('Required parameter appointmentDTO was null or undefined when calling createAppointment.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (waitingListId !== undefined && waitingListId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>waitingListId, 'waitingListId');
        }
        if (allowAppointmentOverlap !== undefined && allowAppointmentOverlap !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>allowAppointmentOverlap, 'allowAppointmentOverlap');
        }

        let headers = this.defaultHeaders;
        if (bookingLink !== undefined && bookingLink !== null) {
            headers = headers.set('bookingLink', String(bookingLink));
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<AppointmentDTO>(`${this.configuration.basePath}/classic-appointment`,
            appointmentDTO,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create multiple new classic appointments. Performed only by Care Provider.
     * @param bookingLink Booking link template
     * @param allowAppointmentOverlap Allow appointment overlap
     * @param appointmentDTO New online appointments request body
     * @param mainTreatmentId The ID of a treatment with associations, which should be linked to the waiting list 
     * @param waitingListId Waiting list request id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createMultipleAppointments(bookingLink: string, allowAppointmentOverlap: boolean, appointmentDTO: Array<AppointmentDTO>, mainTreatmentId?: number, waitingListId?: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<AppointmentDTO>>;
    public createMultipleAppointments(bookingLink: string, allowAppointmentOverlap: boolean, appointmentDTO: Array<AppointmentDTO>, mainTreatmentId?: number, waitingListId?: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<AppointmentDTO>>>;
    public createMultipleAppointments(bookingLink: string, allowAppointmentOverlap: boolean, appointmentDTO: Array<AppointmentDTO>, mainTreatmentId?: number, waitingListId?: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<AppointmentDTO>>>;
    public createMultipleAppointments(bookingLink: string, allowAppointmentOverlap: boolean, appointmentDTO: Array<AppointmentDTO>, mainTreatmentId?: number, waitingListId?: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (bookingLink === null || bookingLink === undefined) {
            throw new Error('Required parameter bookingLink was null or undefined when calling createMultipleAppointments.');
        }
        if (allowAppointmentOverlap === null || allowAppointmentOverlap === undefined) {
            throw new Error('Required parameter allowAppointmentOverlap was null or undefined when calling createMultipleAppointments.');
        }
        if (appointmentDTO === null || appointmentDTO === undefined) {
            throw new Error('Required parameter appointmentDTO was null or undefined when calling createMultipleAppointments.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (mainTreatmentId !== undefined && mainTreatmentId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>mainTreatmentId, 'mainTreatmentId');
        }
        if (waitingListId !== undefined && waitingListId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>waitingListId, 'waitingListId');
        }
        if (allowAppointmentOverlap !== undefined && allowAppointmentOverlap !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>allowAppointmentOverlap, 'allowAppointmentOverlap');
        }

        let headers = this.defaultHeaders;
        if (bookingLink !== undefined && bookingLink !== null) {
            headers = headers.set('bookingLink', String(bookingLink));
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<Array<AppointmentDTO>>(`${this.configuration.basePath}/classic-appointments`,
            appointmentDTO,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete an existent classic appointment by Id. Performed only by care provider.
     * @param id ID
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteAppointment(id: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<any>;
    public deleteAppointment(id: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpResponse<any>>;
    public deleteAppointment(id: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpEvent<any>>;
    public deleteAppointment(id: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined}): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling deleteAppointment.');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.delete<any>(`${this.configuration.basePath}/classic-appointment/${encodeURIComponent(String(id))}`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete patients appointments
     * @param careProviderId Current Care Provider Id
     * @param ids List of patient ids
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deletePatientsAppointments(careProviderId: number, ids: Array<number>, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<PatientDetailsInfoDTO>;
    public deletePatientsAppointments(careProviderId: number, ids: Array<number>, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<PatientDetailsInfoDTO>>;
    public deletePatientsAppointments(careProviderId: number, ids: Array<number>, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<PatientDetailsInfoDTO>>;
    public deletePatientsAppointments(careProviderId: number, ids: Array<number>, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (careProviderId === null || careProviderId === undefined) {
            throw new Error('Required parameter careProviderId was null or undefined when calling deletePatientsAppointments.');
        }
        if (ids === null || ids === undefined) {
            throw new Error('Required parameter ids was null or undefined when calling deletePatientsAppointments.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (careProviderId !== undefined && careProviderId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>careProviderId, 'careProviderId');
        }
        if (ids) {
            ids.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'ids');
            })
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.delete<PatientDetailsInfoDTO>(`${this.configuration.basePath}/appointments`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get an existent appointment by Id. Performed only by care provider.
     * @param id ID
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAppointment(id: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<AppointmentDTO>;
    public getAppointment(id: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<AppointmentDTO>>;
    public getAppointment(id: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<AppointmentDTO>>;
    public getAppointment(id: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getAppointment.');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<AppointmentDTO>(`${this.configuration.basePath}/classic-appointment/${encodeURIComponent(String(id))}`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get appointments
     * @param pageableAppointmentInfosRequestDTO Object containing data necessary for getting a pageable list of Appointment infos
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAppointments(pageableAppointmentInfosRequestDTO: PageableAppointmentInfosRequestDTO, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<PageableAppointmentInfosResponseDTO>;
    public getAppointments(pageableAppointmentInfosRequestDTO: PageableAppointmentInfosRequestDTO, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<PageableAppointmentInfosResponseDTO>>;
    public getAppointments(pageableAppointmentInfosRequestDTO: PageableAppointmentInfosRequestDTO, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<PageableAppointmentInfosResponseDTO>>;
    public getAppointments(pageableAppointmentInfosRequestDTO: PageableAppointmentInfosRequestDTO, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (pageableAppointmentInfosRequestDTO === null || pageableAppointmentInfosRequestDTO === undefined) {
            throw new Error('Required parameter pageableAppointmentInfosRequestDTO was null or undefined when calling getAppointments.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<PageableAppointmentInfosResponseDTO>(`${this.configuration.basePath}/appointments`,
            pageableAppointmentInfosRequestDTO,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get appointments number
     * @param careProviderId Care provider id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAppointmentsNumber(careProviderId: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<AppointmentsCountDTO>;
    public getAppointmentsNumber(careProviderId: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<AppointmentsCountDTO>>;
    public getAppointmentsNumber(careProviderId: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<AppointmentsCountDTO>>;
    public getAppointmentsNumber(careProviderId: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (careProviderId === null || careProviderId === undefined) {
            throw new Error('Required parameter careProviderId was null or undefined when calling getAppointmentsNumber.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (careProviderId !== undefined && careProviderId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>careProviderId, 'careProviderId');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<AppointmentsCountDTO>(`${this.configuration.basePath}/appointments/number`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Check if a duration is open
     * @param availableSlotDTO Object containing necessary data for checking if a slot is open
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAvailableSlots(availableSlotDTO: AvailableSlotDTO, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<FreeSlotsResponseDTO>;
    public getAvailableSlots(availableSlotDTO: AvailableSlotDTO, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<FreeSlotsResponseDTO>>;
    public getAvailableSlots(availableSlotDTO: AvailableSlotDTO, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<FreeSlotsResponseDTO>>;
    public getAvailableSlots(availableSlotDTO: AvailableSlotDTO, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (availableSlotDTO === null || availableSlotDTO === undefined) {
            throw new Error('Required parameter availableSlotDTO was null or undefined when calling getAvailableSlots.');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<FreeSlotsResponseDTO>(`${this.configuration.basePath}/classic-appointment/available-slots`,
            availableSlotDTO,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gets patient statistics like last appointment and count
     * @param ids List of ids
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPatientStatistics(ids?: Array<number>, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<PatientAppointmentStatisticsDTO>>;
    public getPatientStatistics(ids?: Array<number>, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<PatientAppointmentStatisticsDTO>>>;
    public getPatientStatistics(ids?: Array<number>, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<PatientAppointmentStatisticsDTO>>>;
    public getPatientStatistics(ids?: Array<number>, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (ids) {
            ids.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'ids');
            })
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<Array<PatientAppointmentStatisticsDTO>>(`${this.configuration.basePath}/appointments/patient-statistics`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Read GDPR related info for patients
     * @param careProviderId Current Care Provider Id
     * @param ids List of patient ids
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPatientsAppointmentInfo(careProviderId: number, ids: Array<number>, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<PatientDetailsInfoDTO>;
    public getPatientsAppointmentInfo(careProviderId: number, ids: Array<number>, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<PatientDetailsInfoDTO>>;
    public getPatientsAppointmentInfo(careProviderId: number, ids: Array<number>, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<PatientDetailsInfoDTO>>;
    public getPatientsAppointmentInfo(careProviderId: number, ids: Array<number>, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (careProviderId === null || careProviderId === undefined) {
            throw new Error('Required parameter careProviderId was null or undefined when calling getPatientsAppointmentInfo.');
        }
        if (ids === null || ids === undefined) {
            throw new Error('Required parameter ids was null or undefined when calling getPatientsAppointmentInfo.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (careProviderId !== undefined && careProviderId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>careProviderId, 'careProviderId');
        }
        if (ids) {
            ids.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'ids');
            })
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<PatientDetailsInfoDTO>(`${this.configuration.basePath}/appointments/gdpr/patients`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get appointments by ids
     * @param ids Appointment ids
     * @param careProviderId Care provider id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPublicAppointmentsByIds(ids: Array<number>, careProviderId: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<PublicAppointmentDTO>>;
    public getPublicAppointmentsByIds(ids: Array<number>, careProviderId: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<PublicAppointmentDTO>>>;
    public getPublicAppointmentsByIds(ids: Array<number>, careProviderId: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<PublicAppointmentDTO>>>;
    public getPublicAppointmentsByIds(ids: Array<number>, careProviderId: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (ids === null || ids === undefined) {
            throw new Error('Required parameter ids was null or undefined when calling getPublicAppointmentsByIds.');
        }
        if (careProviderId === null || careProviderId === undefined) {
            throw new Error('Required parameter careProviderId was null or undefined when calling getPublicAppointmentsByIds.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (ids) {
            ids.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'ids');
            })
        }
        if (careProviderId !== undefined && careProviderId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>careProviderId, 'careProviderId');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<Array<PublicAppointmentDTO>>(`${this.configuration.basePath}/classic-appointment`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Read GDPR related info for a resource
     * @param careProviderId Care provider id
     * @param resourceId Resuource id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getResourceGdprInfo(careProviderId: number, resourceId: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<ResourceGdprDTO>;
    public getResourceGdprInfo(careProviderId: number, resourceId: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<ResourceGdprDTO>>;
    public getResourceGdprInfo(careProviderId: number, resourceId: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<ResourceGdprDTO>>;
    public getResourceGdprInfo(careProviderId: number, resourceId: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (careProviderId === null || careProviderId === undefined) {
            throw new Error('Required parameter careProviderId was null or undefined when calling getResourceGdprInfo.');
        }
        if (resourceId === null || resourceId === undefined) {
            throw new Error('Required parameter resourceId was null or undefined when calling getResourceGdprInfo.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (careProviderId !== undefined && careProviderId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>careProviderId, 'careProviderId');
        }
        if (resourceId !== undefined && resourceId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>resourceId, 'resourceId');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<ResourceGdprDTO>(`${this.configuration.basePath}/appointments/gdpr/resources`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Patch an existent classic appointment. Performed only by Care Provider.
     * @param id ID
     * @param allowAppointmentOverlap Allow appointment overlap
     * @param requestBody Patch request body
     * @param sendSms Send notification SMS to the patient or not.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public patchAppointment(id: number, allowAppointmentOverlap: boolean, requestBody: { [key: string]: any; }, sendSms?: boolean, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<AppointmentDTO>;
    public patchAppointment(id: number, allowAppointmentOverlap: boolean, requestBody: { [key: string]: any; }, sendSms?: boolean, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<AppointmentDTO>>;
    public patchAppointment(id: number, allowAppointmentOverlap: boolean, requestBody: { [key: string]: any; }, sendSms?: boolean, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<AppointmentDTO>>;
    public patchAppointment(id: number, allowAppointmentOverlap: boolean, requestBody: { [key: string]: any; }, sendSms?: boolean, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling patchAppointment.');
        }
        if (allowAppointmentOverlap === null || allowAppointmentOverlap === undefined) {
            throw new Error('Required parameter allowAppointmentOverlap was null or undefined when calling patchAppointment.');
        }
        if (requestBody === null || requestBody === undefined) {
            throw new Error('Required parameter requestBody was null or undefined when calling patchAppointment.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (sendSms !== undefined && sendSms !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>sendSms, 'sendSms');
        }
        if (allowAppointmentOverlap !== undefined && allowAppointmentOverlap !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>allowAppointmentOverlap, 'allowAppointmentOverlap');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.patch<AppointmentDTO>(`${this.configuration.basePath}/classic-appointment/${encodeURIComponent(String(id))}`,
            requestBody,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Patch appointment and waiting list entities after patient update
     * @param careProviderId Care provider id
     * @param patientId Patient id
     * @param patientDetailsDTO Patient details request body
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateAfterPatchedPatient(careProviderId: number, patientId: number, patientDetailsDTO: PatientDetailsDTO, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<any>;
    public updateAfterPatchedPatient(careProviderId: number, patientId: number, patientDetailsDTO: PatientDetailsDTO, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpResponse<any>>;
    public updateAfterPatchedPatient(careProviderId: number, patientId: number, patientDetailsDTO: PatientDetailsDTO, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpEvent<any>>;
    public updateAfterPatchedPatient(careProviderId: number, patientId: number, patientDetailsDTO: PatientDetailsDTO, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined}): Observable<any> {
        if (careProviderId === null || careProviderId === undefined) {
            throw new Error('Required parameter careProviderId was null or undefined when calling updateAfterPatchedPatient.');
        }
        if (patientId === null || patientId === undefined) {
            throw new Error('Required parameter patientId was null or undefined when calling updateAfterPatchedPatient.');
        }
        if (patientDetailsDTO === null || patientDetailsDTO === undefined) {
            throw new Error('Required parameter patientDetailsDTO was null or undefined when calling updateAfterPatchedPatient.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (careProviderId !== undefined && careProviderId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>careProviderId, 'careProviderId');
        }
        if (patientId !== undefined && patientId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>patientId, 'patientId');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.patch<any>(`${this.configuration.basePath}/classic-appointments`,
            patientDetailsDTO,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
