import { isNullOrUndefined, isObject, isString } from './helpers';
import { AppointmentTokenSchema } from '@ap-angular/shared/schema';

export class UrlEmbedData {
	private static encode(value: any): string {
		return value ? btoa(JSON.stringify(value)) : undefined;
	}

	private static decode(value: string): any {
		return isString(value) ? JSON.parse(atob(value)) : undefined;
	}

	public static encodeAppointmentToken(value: AppointmentTokenSchema): string {
		return isObject(value) ? this.encode(value) : undefined;
	}

	public static decodeAppointmentToken(value: string): AppointmentTokenSchema {
		return isString(value) ? this.decode(value) : undefined;
	}

	public static appointmentTokenValidation(value: string): boolean {
		if (isString(value)) {
			const data: AppointmentTokenSchema = this.decode(value);
			return !isNullOrUndefined(data.startTime && data.endTime && data.widgetToken);
		}
		return undefined;
	}

	public static encodeDate(value: any): string {
		return this.encode({date: value});
	}

	public static decodeDate(value: string): string {
		return this.decode(value).date;
	}

	public static encodeString(value: string): string {
		return this.encode({string: value});
	}

	public static decodeString(value: string): string {
		return this.decode(value).string;
	}
}
