/**
 * Appointment Planner - Care Providers REST API
 * Appointment Planner Care Providers Service REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: valeriu.craciun@kronsoft.ro
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Care provider data types
 */
export type CareProviderDataEnumDTO = 'OPEN_HOURS' | 'MEDICAL_SERVICES' | 'EXPERTISE_AREAS' | 'PROFESSIONS' | 'SETTINGS' | 'MESSAGE_TEMPLATES' | 'EXTRA_INFO' | 'TREATMENTS' | 'RESOURCES' | 'USERS';

export const CareProviderDataEnumDTO = {
    OPENHOURS: 'OPEN_HOURS' as CareProviderDataEnumDTO,
    MEDICALSERVICES: 'MEDICAL_SERVICES' as CareProviderDataEnumDTO,
    EXPERTISEAREAS: 'EXPERTISE_AREAS' as CareProviderDataEnumDTO,
    PROFESSIONS: 'PROFESSIONS' as CareProviderDataEnumDTO,
    SETTINGS: 'SETTINGS' as CareProviderDataEnumDTO,
    MESSAGETEMPLATES: 'MESSAGE_TEMPLATES' as CareProviderDataEnumDTO,
    EXTRAINFO: 'EXTRA_INFO' as CareProviderDataEnumDTO,
    TREATMENTS: 'TREATMENTS' as CareProviderDataEnumDTO,
    RESOURCES: 'RESOURCES' as CareProviderDataEnumDTO,
    USERS: 'USERS' as CareProviderDataEnumDTO
};

