/**
 * Appointment Planner - Online Appointments REST API
 * Appointment Planner Online Appointments Service REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: valeriu.craciun@kronsoft.ro
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Status Enum
 */
export type AppointmentStatusEnumDTO = 'PENDING' | 'CONFIRMED' | 'CANCELED' | 'COMPLETED' | 'PENDING_CANCELLATION' | 'IN_PROGRESS';

export const AppointmentStatusEnumDTO = {
    PENDING: 'PENDING' as AppointmentStatusEnumDTO,
    CONFIRMED: 'CONFIRMED' as AppointmentStatusEnumDTO,
    CANCELED: 'CANCELED' as AppointmentStatusEnumDTO,
    COMPLETED: 'COMPLETED' as AppointmentStatusEnumDTO,
    PENDINGCANCELLATION: 'PENDING_CANCELLATION' as AppointmentStatusEnumDTO,
    INPROGRESS: 'IN_PROGRESS' as AppointmentStatusEnumDTO
};

