export * from './absentHours.service';
import { AbsentHoursService } from './absentHours.service';
export * from './bookingHours.service';
import { BookingHoursService } from './bookingHours.service';
export * from './cPInternal.service';
import { CPInternalService } from './cPInternal.service';
export * from './expertiseArea.service';
import { ExpertiseAreaService } from './expertiseArea.service';
export * from './keyValuePair.service';
import { KeyValuePairService } from './keyValuePair.service';
export * from './kpi.service';
import { KpiService } from './kpi.service';
export * from './language.service';
import { LanguageService } from './language.service';
export * from './medicalService.service';
import { MedicalServiceService } from './medicalService.service';
export * from './messageTemplate.service';
import { MessageTemplateService } from './messageTemplate.service';
export * from './onlineBookingHours.service';
import { OnlineBookingHoursService } from './onlineBookingHours.service';
export * from './periodicalDeletion.service';
import { PeriodicalDeletionService } from './periodicalDeletion.service';
export * from './person.service';
import { PersonService } from './person.service';
export * from './portal.service';
import { PortalService } from './portal.service';
export * from './profession.service';
import { ProfessionService } from './profession.service';
export * from './resource.service';
import { ResourceService } from './resource.service';
export * from './resourceOverview.service';
import { ResourceOverviewService } from './resourceOverview.service';
export * from './settings.service';
import { SettingsService } from './settings.service';
export * from './treatmentAssociation.service';
import { TreatmentAssociationService } from './treatmentAssociation.service';
export * from './treatmentCategory.service';
import { TreatmentCategoryService } from './treatmentCategory.service';
export * from './treatments.service';
import { TreatmentsService } from './treatments.service';
export const APIS = [AbsentHoursService, BookingHoursService, CPInternalService, ExpertiseAreaService, KeyValuePairService, KpiService, LanguageService, MedicalServiceService, MessageTemplateService, OnlineBookingHoursService, PeriodicalDeletionService, PersonService, PortalService, ProfessionService, ResourceService, ResourceOverviewService, SettingsService, TreatmentAssociationService, TreatmentCategoryService, TreatmentsService];
