import { Analytics } from '@ap-angular/service/analytics';
import { GoogleAnalyticsService } from '../google/google-analytics.service';

declare let Piwik: any;

export class MatomoAnalyticsService implements GoogleAnalyticsService {

	private _analyticsQueue;

	private _tracker;

	public isReady = false;

	constructor() {
	}

	public init(analytics: Analytics): void {
		const matomoJS = document.createElement('script');
		matomoJS.type = 'text/javascript';
		matomoJS.async = true;
		matomoJS.defer = true;
		matomoJS.src = analytics.url + 'matomo.js';

		(window as any)._paq = (window as any)._paq || [];
		this._analyticsQueue = (window as any)._paq;
		this._analyticsQueue.push(['trackPageView']);
		this._analyticsQueue.push(['enableLinkTracking']);
		this._analyticsQueue.push(['setTrackerUrl', analytics.url + 'matomo.php']);
		this._analyticsQueue.push(['setSiteId', analytics.appId]);

		this.isReady = true;

		document.head.appendChild(matomoJS);
	}

	public config(analytics: Analytics, pageTitle?: string, pagePath?: string): void {
		// dummy implementation, this._analyticsQueue.push(['trackPageView']); should suffice
	}

	public emitScreen(analytics: Analytics, screen: string): void {
		if ((window as any).Piwik) {
			if (!this._tracker) {
				this._tracker = Piwik.getTracker(analytics.url + 'matomo.php', analytics.appId);
			}
			this._tracker.trackPageView(screen);
		} else {
			this._analyticsQueue.push(['trackPageView', screen]);
		}
	}

	public emitEvent(analytics: Analytics, eventCategory: string, eventAction?: string, eventLabel?: string, eventValue?: number): void {
		if ((window as any).Piwik) {
			if (!this._tracker) {
				this._tracker = Piwik.getTracker(analytics.url + 'matomo.php', analytics.appId);
			}
			this._tracker.trackEvent(eventCategory, eventAction, eventLabel, eventValue);
		} else {
			this._analyticsQueue.push(['trackEvent', eventCategory, eventAction, eventLabel, eventValue]);
		}
	}
}
