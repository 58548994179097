export enum ProviderEnum {
	salutation = 'App.General.Salutation',
	academicalDegree = 'App.General.Title',
	firstname = 'App.General.Firstname',
	lastname = 'App.General.Lastname',
	practiceName = 'App.General.Practice_Name',
	noventiCustomerNumber = 'App.General.Med_Day_Custom_Number',
	telephone = 'App.General.Phone',
	fax = 'App.General.Fax',
	email = 'App.General.Email',
	url = 'App.General.Homepage',
	street = 'App.General.Street',
	houseNumber = 'App.General.HouseNo',
	postalCode = 'App.General.Postcode',
	city = 'App.General.City'
}

export enum UserEnum {
	roles = 'App.General.User_Role',
	firstName = 'App.General.Firstname',
	lastName = 'App.General.Lastname',
	email = 'App.General.Email',
	username = 'App.General.Username',
	phone = 'App.General.Phone',
	oldPassword = 'App.General.Old_Password',
	password = 'App.General.Password',
	confirmPassword = 'App.General.Password_Confirm',
	passwordGroup = 'App.General.Password_Or_Password_Confirm',
	active = 'App.General.Active'
}

export enum SmsEnum {
	content = 'App.General.SMS'
}

export enum TemplateEmailEnum {
	contentHeader = 'Api.ProviderProfile.Institution_Settings.Text_Template.Email.Header',
	contentBody = 'Api.ProviderProfile.Institution_Settings.Text_Template.Email.Content',
	contentFooter = 'Api.ProviderProfile.Institution_Settings.Text_Template.Email.Footer'
}

export enum PatientAdminEnum {
	gender = 'App.General.Salutation',
	firstname = 'App.General.Firstname',
	lastname = 'App.General.Lastname',
	birthdate = 'App.General.Birthday',
	insuranceType = 'App.General.Insurance_Type',
	telephone = 'App.General.Mobile_Phone',
	email = 'App.General.Email',
	street = 'App.General.Street',
	houseNumber = 'App.General.HouseNo',
	postalCode = 'App.General.Postcode',
	city = 'App.General.City',
	note = 'App.General.Note'
}

export enum PatientBookingEnum {
	salutation = 'App.General.Salutation',
	phone = 'App.General.Mobile_Phone',
	firstname = 'App.General.Firstname',
	lastname = 'App.General.Lastname',
	email = 'App.General.Email',
	accept = 'Page.Appointment.Request.Label_Terms_And_Privacy',
	telephone = 'App.General.Mobile_Phone'
}

export enum ResourceEnum {
	firstName = 'App.General.Firstname',
	lastName = 'App.General.Lastname',
	color = 'App.General.Color'
}

export enum GeneralDataEnum {
	name = 'App.General.Name'
}


export enum ExtraInfoEnum {
	publicInsured = 'Pages.Provider.Extra_Info.Payment.Public',
	privateInsured = 'Pages.Provider.Extra_Info.Payment.Private'
}

export enum CalendarSettingsEnum {
	calendarDaysVisible = 'Pages.Provider.Calendar_Settings.Displayed_Days',
	slotDuration = 'Pages.Provider.Calendar_Settings.Standard_Slot_Time',
	inteval = 'Modal.Provider.Calendar.Start'
}
export enum CMSEnum {
	title = 'App.General.Title',
	url = 'App.General.Url',
	content = 'App.General.Content'
}

export enum TreatmentEnum {
	name = 'Pages.Provider.Treatment.Form.Name.Label',
	duration = 'Pages.Provider.Treatment.Form.Duration.Label',
	bufferTimeBefore = 'App.General.Buffer_Time_Before',
	bufferTimeAfter = 'App.General.Buffer_Time_After'
}

export enum RegistrationEnum {
	email = 'App.General.Email',
	phoneNumber = 'App.General.Phone',
}

export enum ProviderUserRegistrationEnum {
	role = 'App.General.User_Role',
	name = 'App.General.Name',
	email = 'App.General.Email',
	phoneNumber = 'App.General.Mobile_Phone',
	active = 'App.General.Active'
}

export enum AppointmentSettingsEnum {
	appointmentCancelLimit = 'Api.ProviderProfile.Institution_Settings.Appointment.Cancel_Appointment_Time',
	appointmentBookingLimit = 'Api.ProviderProfile.Institution_Settings.Appointment.Restrict_Booking_Time',
	allowWaitingList = 'Api.ProviderProfile.Institution_Settings.Appointment.Waiting_List_Title',
}

export enum GDPREnum {
	dsgvoPeriod = 'Api.ProviderProfile.Institution_Settings.GDPR.Storage_Period_For_Patient_Data',
}

export enum InstitutionEnum {
	practiceName = 'Api.ProviderInstitution.Create_Modal.Location_Name',
	telephone = 'App.General.Phone',
	fax = 'App.General.Fax',
	email = 'App.General.Email',
	url = 'App.General.Homepage',
	street = 'App.General.Street',
	houseNumber = 'App.General.HouseNo',
	postalCode = 'App.General.Postcode',
	city = 'App.General.City'
}

export enum InstitutionCopyDataEnum {
	from = 'Api.ProviderInstitution.Create_Modal.Select'
}

export enum ProviderNotificationSettings {
	allowNotifications = 'Pages.Provider.Settings.Notification.Toggle'
}

export type WeekdaysEnum = 'MONDAY' |  'TUESDAY' |  'WEDNESDAY' |  'THURSDAY' | 'FRIDAY' | 'SATURDAY' | 'SUNDAY';

export const WEEKDAYS: WeekdaysEnum[] = [
	'MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY', 'SUNDAY'
];
