import {
	AfterViewInit,
	ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding, Inject, ViewChild, ViewEncapsulation
} from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { Observable, combineLatest } from 'rxjs';
import { ToastContainerDirective } from 'ngx-toastr';
import { PAGE } from '@ap-angular/shared/schema';
import { UserService } from '@ap-angular/service/user';
import { AbstractComponent } from '@ap-angular/shared/abstract';
import { ApplicationService, MEDIA_QUERY_SIZE } from '@ap-angular/service/application';
import { debounceTime } from 'rxjs/operators';
import { MatButton } from '@angular/material/button';

@Component({
	selector: 'ap-admin-template',
	templateUrl: './template.component.html',
	styleUrls: ['./template.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None
})
export class AdminTemplateComponent extends AbstractComponent implements AfterViewInit {

	@HostBinding('class') class = 'template';

	@ViewChild('sidenav', {static: true}) public sidenav: MatSidenav;

	@ViewChild(ToastContainerDirective, {static: true}) toastContainer: ToastContainerDirective;

	@ViewChild('headerUser') public headerUser: MatButton;

	public readonly PAGE = PAGE;

	public permission$ = this._userService.getPermission$();

	public name$: Observable<string> = this._userService.getAccountName$();

	public email$: Observable<string> = this._userService.getAccountEmail$();

	public env: { version: string, production: string };

	constructor(
		@Inject('ENVIRONMENT') private readonly _environment: any,
		private readonly _changeDetectorRef: ChangeDetectorRef,
		private readonly _userService: UserService,
		private readonly _applicationService: ApplicationService
	) {
		super();
		this.env = {version: this._environment.version, production: this._environment.production};

		this._changeDetectorRef.markForCheck();
	}

	public ngAfterViewInit(): void {

		
		super.addSubscription(combineLatest([this._applicationService.getMediaQuerySize$(), this.name$]).pipe(debounceTime(100)).subscribe(([querySize, name]) => {
			const smallerThenLarge = querySize === MEDIA_QUERY_SIZE.LARGE || querySize === MEDIA_QUERY_SIZE.SMALL || querySize === MEDIA_QUERY_SIZE.MEDIUM;
			
			if(smallerThenLarge) this._applicationService.setPositionOfSearchInput(0);
			else this._applicationService.setPositionOfSearchInput( this.headerUser._getHostElement().offsetWidth);
		}))
	}

	public logout(): void {
		this._userService.logout();
	}
}
