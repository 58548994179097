/**
 * Appointment Planner - Care Providers REST API
 * Appointment Planner Care Providers Service REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: valeriu.craciun@kronsoft.ro
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { OnlineBookingHourDTOAllOf } from './onlineBookingHourDTOAllOf';
import { BaseDTO } from './baseDTO';


/**
 * OnlineBooking Resource
 */
export interface OnlineBookingHourDTO { 
    /**
     * Id of the entity
     */
    id?: number;
    /**
     * Description
     */
    name: string;
    /**
     * Start time
     */
    startTime: string;
    /**
     * End time
     */
    endTime: string;
    /**
     * Start date
     */
    startDate: string;
    /**
     * End date
     */
    endDate?: string;
    /**
     * Flag to indicate whether this is a indefinite interval (does not have an end date).
     */
    indefinite?: boolean;
    /**
     * Flag to indicate whether this appointment is applicable on Monday or not.
     */
    monday?: boolean;
    /**
     * Flag to indicate whether this appointment is applicable on Tuesday or not.
     */
    tuesday?: boolean;
    /**
     * Flag to indicate whether this appointment is applicable on Wednesday or not.
     */
    wednesday?: boolean;
    /**
     * Flag to indicate whether this appointment is applicable on Thursday or not.
     */
    thursday?: boolean;
    /**
     * Flag to indicate whether this appointment is applicable on Friday or not.
     */
    friday?: boolean;
    /**
     * Flag to indicate whether this appointment is applicable on Saturday or not.
     */
    saturday?: boolean;
    /**
     * Flag to indicate whether this appointment is applicable on Sunday or not.
     */
    sunday?: boolean;
    /**
     * Insurance type
     */
    insuranceType?: OnlineBookingHourDTO.InsuranceTypeEnum;
    /**
     * Id of the resource
     */
    resourceId?: number;
    /**
     * The recurrence of the booking hours, in weeks
     */
    recurrenceWeeks: number;
}
export namespace OnlineBookingHourDTO {
    export type InsuranceTypeEnum = 'PRIVATE' | 'PUBLIC';
    export const InsuranceTypeEnum = {
        PRIVATE: 'PRIVATE' as InsuranceTypeEnum,
        PUBLIC: 'PUBLIC' as InsuranceTypeEnum
    };
}


