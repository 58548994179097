import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

import { DialogService } from './dialog.service';
import { DialogContentComponent } from './content/content.component';
import { DialogDeleteComponent } from './delete/delete.component';
import { DialogDeleteDataComponent } from './delete-data/delete-data.component';
import { DialogWidgetComponent } from './widget/widget.component';
import { SharedMatFormModule } from '@ap-angular/shared/module';

@NgModule({
	declarations: [
		DialogContentComponent,
		DialogDeleteComponent,
		DialogDeleteDataComponent,
		DialogWidgetComponent
	],
	imports: [
		CommonModule,

		TranslateModule,

		MatButtonModule,
		MatButtonToggleModule,
		MatDialogModule,
		MatIconModule,
		MatTooltipModule,
		MatSlideToggleModule,

		//TODO: find a way to remove forms from modals
		SharedMatFormModule
	],
	exports: [
		DialogContentComponent,
		DialogDeleteComponent,
		DialogDeleteDataComponent,
		DialogWidgetComponent
	]
})
export class ServiceDialogModule {
	static forRoot(): ModuleWithProviders<ServiceDialogModule> {
		return {
			ngModule: ServiceDialogModule,
			providers: [DialogService]
		};
	}
}
