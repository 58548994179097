export interface Analytics {
	provider?: 'google' | 'matomo';
	appId?: string;
	url?: string;
	token?: string;
	appName?: string;
	appVersion?: string;
}

export class AnalyticsConstants {

	/* ################## CATEGORY #################### */
	public static readonly ADMIN_APP_CATEGORY = 'Admin';
	public static readonly BOOKING_APP_CATEGORY = 'Booking'; //
	public static readonly PARTNER_APP_CATEGORY = 'Partner';
	public static readonly WIDGET_APP_CATEGORY = 'Widget';

	/* #################### ACTION #################### */

	/* #################### AUTHENTICATION ############## */
	public static readonly ACTION_LOGIN = '[Action] Click Login';
	public static readonly ACTION_LOGOUT = '[Action] Click Logout';

	/* #################### MFA ############## */
	public static readonly ACTION_CLICK_SKIP_MFA = '[Action] Click Skip MFA';
	public static readonly ACTION_CLICK_ACTIVATE_MFA = '[Action] Click Activate MFA';
	public static readonly ACTION_CLICK_SEND_MFA_ACTIVATION_CODE = '[Action] Click Send MFA Activation Code';
	public static readonly ACTION_CLICK_RESEND_MFA_CODE = '[Action] Click Resend MFA code';

	/* #################### APPOINTMENT PLANNER ############## */
	public static readonly ACTION_CLICK_OPEN_ADD_APPOINTMENT_MODAL = '[Action] Click Open Add Appointment Modal';
	public static readonly ACTION_CLICK_ADD_APPOINTMENT = '[Action] Click Add Appointment';
	public static readonly ACTION_CLICK_CANCEL_ADD_APPOINTMENT = '[Action] Click Cancel Add Appointment';
	public static readonly ACTION_CLICK_OPEN_EDIT_APPOINTMENT_MODAL = '[Action] Click Open Edit Appointment Modal';
	public static readonly ACTION_CLICK_EDIT_APPOINTMENT = '[Action] Click Edit Appointment';
	public static readonly ACTION_CLICK_CANCEL_EDIT_APPOINTMENT = '[Action] Click Cancel Edit Appointment';
	public static readonly ACTION_CLICK_OPEN_DELETE_APPOINTMENT_MODAL = '[Action] Click Open Delete Appointment Modal';
	public static readonly ACTION_CLICK_DELETE_APPOINTMENT = '[Action] Click Delete Appointment';

	public static readonly ACTION_CLICK_FIX_APPOINTMENT_TYPE = '[Action] Click Select Fix Appointment Type';
	public static readonly ACTION_CLICK_INTERVAL_APPOINTMENT_TYPE = '[Action] Click Select Interval Appointment Type';

	public static readonly ACTION_CLICK_CONFIRM_APPOINTMENT = '[Action] Click Confirm Appointment';

	public static readonly ACTION_CLICK_SAVE_APPOINTMENTS_WITHOUT_CONTACT_DATA = '[Action] Click Save Appointment Without Contact Data';
	public static readonly ACTION_CLICK_GO_BACK_TO_FORMULAR_FOR_APPOINTMENT_CONTACT_DATA = '[Action] Click Go Back To Form For Appointment Contact Data';

	public static readonly ACTION_CLICK_SHOW_TODAY_APPOINTMENTS = '[Action] Click Show Today Appointments';

	public static readonly ACTION_CLICK_PRINT_APPOINTMENTS = '[Action] Click Print Appointments';
	public static readonly ACTION_CLICK_CANCEL_PRINT_APPOINTMENTS = '[Action] Click Cancel Print Appointments';

	public static readonly ACTION_CLICK_OPEN_APPOINTMENT_PLANNER_SETTINGS = '[Action] Click Open Appointment Planner Settings';
	public static readonly ACTION_CLICK_SAVE_APPOINTMENT_PLANNER_SETTINGS = '[Action] Click Save Appointment Planner Settings';
	public static readonly ACTION_CLICK_CANCEL_SAVE_APPOINTMENT_PLANNER_SETTINGS = '[Action] Cancel Save Appointment Planner Settings';

	/* #################### PATIENTS ############## */
	public static readonly ACTION_CLICK_OPEN_ADD_PATIENT_MODAL = '[Action] Click Open Add Patient Modal';
	public static readonly ACTION_CLICK_ADD_PATIENT = '[Action] Click Add Patient';
	public static readonly ACTION_CLICK_CANCEL_ADD_PATIENT = '[Action] Click Cancel Add Patient';

	public static readonly ACTION_CLICK_OPEN_EDIT_PATIENT_VIEW = '[Action] Click Open Edit Patient View';
	public static readonly ACTION_CLICK_OPEN_EDIT_PATIENT_MODAL = '[Action] Click Open Edit Patient Modal';
	public static readonly ACTION_CLICK_EDIT_PATIENT = '[Action] Click Edit Patient';
	public static readonly ACTION_CLICK_CANCEL_EDIT_PATIENT = '[Action] Click Cancel Edit Patient';

	public static readonly ACTION_CLICK_SAVE_PATIENT_WITHOUT_CONTACT_DATA = '[Action] Click Save Patient Without Contact Data';
	public static readonly ACTION_CLICK_GO_BACK_TO_FORMULAR_FOR_PATIENT_CONTACT_DATA = '[Action] Click Go Back To Form For Patient Contact Data';

	public static readonly ACTION_CLICK_OPEN_MERGE_PATIENT_VIEW = '[Action] Click Open Merge Patient View';
	public static readonly ACTION_CLICK_MERGE_PATIENT = '[Action] Click Merge Patient';

	public static readonly ACTION_CLICK_OPEN_DELETE_PATIENT_MODAL = '[Action] Click Open Delete Patient Modal';
	public static readonly ACTION_CLICK_DELETE_PATIENT = '[Action] Click Delete Patient';
	public static readonly ACTION_CLICK_CANCEL_DELETE_PATIENT = '[Action] Click Cancel Delete Patient';

	public static readonly ACTION_CLICK_ADD_APPOINTMENT_FROM_PATIENT_VIEW = '[Action] Click Add Appointment From Patient View';

	public static readonly ACTION_CLICK_OPEN_BLOCK_PATIENT_MODAL = '[Action] Click Open Block Patient Modal';
	public static readonly ACTION_CLICK_BLOCK_PATIENT = '[Action] Click Block Patient';

	/* #################### INSTITUTION DATA ############## */
	public static readonly ACTION_CLICK_CREATE_PROFILE_INFORMATION = '[Action] Click Create Profile Information';
	public static readonly ACTION_CLICK_EDIT_PROFILE_INFORMATION = '[Action] Click Edit Profile Information';

	/* #################### TREATMENTS ############## */
	public static readonly ACTION_CLICK_OPEN_ADD_CATEGORY_MODAL = '[Action] Click Open Add Category Modal';
	public static readonly ACTION_CLICK_ADD_CATEGORY = '[Action] Click Add Category';
	public static readonly ACTION_CLICK_CANCEL_ADD_CATEGORY = '[Action] Click Cancel Add Category';
	public static readonly ACTION_CLICK_OPEN_EDIT_CATEGORY_MODAL = '[Action] Click Open Edit Category Modal';
	public static readonly ACTION_CLICK_EDIT_CATEGORY = '[Action] Click Edit Category';
	public static readonly ACTION_CLICK_CANCEL_EDIT_CATEGORY = '[Action] Click Cancel Edit Category';
	public static readonly ACTION_CLICK_OPEN_DELETE_CATEGORY_MODAL = '[Action] Click Open Delete Category Modal';
	public static readonly ACTION_CLICK_DELETE_CATEGORY = '[Action] Click Delete Category';

	public static readonly ACTION_CLICK_OPEN_ADD_TREATMENT_MODAL = '[Action] Click Open Add Treatment Modal';
	public static readonly ACTION_CLICK_ADD_TREATMENT = '[Action] Click Add Treatment';
	public static readonly ACTION_CLICK_CANCEL_ADD_TREATMENT = '[Action] Click Cancel Add Treatment';
	public static readonly ACTION_CLICK_OPEN_EDIT_TREATMENT_VIEW = '[Action] Click Open Edit Treatment View';
	public static readonly ACTION_CLICK_EDIT_TREATMENT = '[Action] Click Edit Treatment';
	public static readonly ACTION_CLICK_CANCEL_EDIT_TREATMENT = '[Action] Click Cancel Edit Treatment';
	public static readonly ACTION_CLICK_OPEN_DELETE_TREATMENT_MODAL = '[Action] Click Open Delete Treatment Modal';
	public static readonly ACTION_CLICK_DELETE_TREATMENT = '[Action] Click Delete Treatment';

	/* #################### DOCTORS ############## */
	public static readonly ACTION_CLICK_TOGGLE_DOCTOR_RANDOM_PRIORITIZATION = '[Action] Click Toggle Doctor Random Prioritization';

	public static readonly ACTION_CLICK_OPEN_REORDER_DOCTORS = '[Action] Click Open Reorder Doctors';
	public static readonly ACTION_CLICK_FINISH_REORDER_DOCTORS = '[Action] Click Finish Reorder Doctors';

	public static readonly ACTION_CLICK_OPEN_ADD_DOCTOR_MODAL = '[Action] Click Open Add Doctor Modal';
	public static readonly ACTION_CLICK_ADD_DOCTOR = '[Action] Click Add Doctor';
	public static readonly ACTION_CLICK_CANCEL_ADD_DOCTOR = '[Action] Click Cancel Add Doctor';

	public static readonly ACTION_CLICK_OPEN_EDIT_DOCTOR_VIEW = '[Action] Click Open Edit Doctor View';
	public static readonly ACTION_CLICK_EDIT_DOCTOR = '[Action] Click Edit Doctor';

	public static readonly ACTION_CLICK_OPEN_DELETE_DOCTOR_MODAL = '[Action] Click Open Delete Doctor Modal';
	public static readonly ACTION_CLICK_DELETE_DOCTOR = '[Action] Click Delete Doctor';
	public static readonly ACTION_CLICK_CANCEL_DELETE_DOCTOR = '[Action] Click Cancel Delete Doctor';

	public static readonly ACTION_CLICK_OPEN_IMPORT_DOCTOR_ABSENT_HOURS_MODAL = '[Action] Click Open Import Doctor Absent Hours Modal';
	public static readonly ACTION_CLICK_IMPORT_DOCTOR_ABSENT_HOURS = '[Action] Click Import Doctor Absent Hours';
	public static readonly ACTION_CLICK_CANCEL_IMPORT_DOCTOR_ABSENT_HOURS = '[Action] Click Cancel Import Doctor Absent Hours';

	public static readonly ACTION_CLICK_OPEN_DOCTOR_SCHEDULE_PREVIEW_VIEW = '[Action] Click Open Doctor Schedule Preview View';
	public static readonly ACTION_CLICK_OPEN_DOCTOR_BUSINESS_HOURS_VIEW = '[Action] Click Open Doctor Business Hours View';
	public static readonly ACTION_CLICK_OPEN_DOCTOR_ONLINE_APPOINTMENTS_VIEW = '[Action] Click Open Doctor Online Appointments View';
	public static readonly ACTION_CLICK_OPEN_DOCTOR_ABSENT_TIMES_VIEW = '[Action] Click Open Doctor Absent Times View';
	public static readonly ACTION_CLICK_OPEN_DOCTOR_GENERAL_INFO_VIEW = '[Action] Click Open Doctor General Info View';

	public static readonly ACTION_CLICK_ADD_DOCTOR_ABSENCE = '[Action] Click Add Doctor Absence';
	public static readonly ACTION_CLICK_EDIT_DOCTOR_ABSENCE = '[Action] Click Edit Doctor Absence';

	public static readonly ACTION_CLICK_ADD_DOCTOR_ONLINE_APPOINTMENT = '[Action] Click Add Doctor Online Appointment';
	public static readonly ACTION_CLICK_EDIT_DOCTOR_ONLINE_APPOINTMENT = '[Action] Click Edit Doctor Online Appointment';

	public static readonly ACTION_CLICK_ADD_DOCTOR_BUSINESS_HOURS = '[Action] Click Add Doctor Business Hours';
	public static readonly ACTION_CLICK_EDIT_DOCTOR_BUSINESS_HOURS = '[Action] Click Edit Doctor Business Hours';

	/* #################### ROOMS ############## */
	public static readonly ACTION_CLICK_OPEN_REORDER_ROOMS = '[Action] Click Open Reorder Rooms';
	public static readonly ACTION_CLICK_FINISH_REORDER_ROOMS = '[Action] Click Finish Reorder Rooms';

	public static readonly ACTION_CLICK_OPEN_ADD_ROOM_MODAL = '[Action] Click Open Add Room Modal';
	public static readonly ACTION_CLICK_ADD_ROOM = '[Action] Click Add Room';
	public static readonly ACTION_CLICK_CANCEL_ADD_ROOM= '[Action] Click Cancel Add Room';

	public static readonly ACTION_CLICK_OPEN_EDIT_ROOM_VIEW = '[Action] Click Open Edit Room View';
	public static readonly ACTION_CLICK_EDIT_ROOM = '[Action] Click Edit Room';

	public static readonly ACTION_CLICK_OPEN_DELETE_ROOM_MODAL = '[Action] Click Open Delete Room Modal';
	public static readonly ACTION_CLICK_DELETE_ROOM = '[Action] Click Delete Room';
	public static readonly ACTION_CLICK_CANCEL_DELETE_ROOM = '[Action] Click Cancel Delete Room';

	public static readonly ACTION_CLICK_OPEN_ROOM_SCHEDULE_PREVIEW_VIEW = '[Action] Click Open Room Schedule Preview View';
	public static readonly ACTION_CLICK_OPEN_ROOM_BUSINESS_HOURS_VIEW = '[Action] Click Open Room Business Hours View';
	public static readonly ACTION_CLICK_OPEN_ROOM_ONLINE_APPOINTMENTS_VIEW = '[Action] Click Open Room Online Appointments View';
	public static readonly ACTION_CLICK_OPEN_ROOM_ABSENT_TIMES_VIEW = '[Action] Click Open Room Absent Times View';
	public static readonly ACTION_CLICK_OPEN_ROOM_GENERAL_INFO_VIEW = '[Action] Click Open Room General Info View';

	public static readonly ACTION_CLICK_ADD_ROOM_ABSENCE = '[Action] Click Add Room Absence';
	public static readonly ACTION_CLICK_EDIT_ROOM_ABSENCE = '[Action] Click Edit Room Absence';

	public static readonly ACTION_CLICK_ADD_ROOM_ONLINE_APPOINTMENT = '[Action] Click Add Room Online Appointment';
	public static readonly ACTION_CLICK_EDIT_ROOM_ONLINE_APPOINTMENT = '[Action] Click Edit Room Online Appointment';

	public static readonly ACTION_CLICK_ADD_ROOM_BUSINESS_HOURS = '[Action] Click Add Room Business Hours';
	public static readonly ACTION_CLICK_EDIT_ROOM_BUSINESS_HOURS = '[Action] Click Edit Room Business Hours';

	/* #################### OPENING - HOURS ############## */
	public static readonly ACTION_CLICK_SAVE_OPENING_HOURS = '[Action] Click Save Opening Hours';

	/* #################### ADDITIONALS ############## */
	public static readonly ACTION_CLICK_SAVE_ADDITIONALS = '[Action] Click Save Additionals';

	/* #################### SETTINGS ############## */
	public static readonly ACTION_CLICK_SAVE_GENERAL_APPOINTMENT_SETTINGS = '[Action] Click Save General Appointment Settings';
	public static readonly ACTION_CLICK_SAVE_EMAIL_TEXT_TEMPLATE_SETTINGS = '[Action] Click Save Email Text Template Settings';
	public static readonly ACTION_CLICK_SAVE_SMS_TEXT_TEMPLATE_SETTINGS = '[Action] Click Save SMS Text Template Settings';
	public static readonly ACTION_CLICK_SAVE_GDPR_SETTINGS = '[Action] Click Save GDPR Settings';

	/* #################### USERS ############## */
	public static readonly ACTION_CLICK_OPEN_CREATE_USER_MODAL = '[Action] Click Open Create User Modal';
	public static readonly ACTION_CLICK_CREATE_NEW_USER = '[Action] Click Save User';
	public static readonly ACTION_CLICK_CANCEL_SAVE_NEW_USER = '[Action] Click Cancel Save User';

	public static readonly ACTION_CLICK_OPEN_EDIT_USER_VIEW = '[Action] Click Open Edit User View';
	public static readonly ACTION_CLICK_EDIT_USER = '[Action] Click Edit User';
	public static readonly ACTION_CLICK_CANCEL_EDIT_USER = '[Action] Click Cancel Edit User';

	public static readonly ACTION_CLICK_OPEN_DELETE_USER_MODAL = '[Action] Click Open Delete User Modal';
	public static readonly ACTION_CLICK_DELETE_USER = '[Action] Click Add User';

	public static readonly ACTION_CLICK_OPEN_DELETE_USER_REGISTRATION_REQUEST_MODAL = '[Action] Click Open Delete User Registration Request Modal';
	public static readonly ACTION_CLICK_DELETE_USER_REGISTRATION_REQUEST = '[Action] Click Delete User Registration Request';

	public static readonly ACTION_CLICK_RESEND_USER_VALIDATION_EMAIL = '[Action] Click Resend User Validation Email';
	public static readonly ACTION_CLICK_RESET_USER_PASSWORD = '[Action] Click Reset User Password';
	public static readonly ACTION_CLICK_RESET_USER_MFA = '[Action] Click Reset User MFA';

	/* #################### WAITING LIST ############## */
	public static readonly ACTION_CLICK_OPEN_ADD_WAITING_LIST_MODAL = '[Action] Click Open Add Waiting List Modal';
	public static readonly ACTION_CLICK_ADD_WAITING_LIST = '[Action] Click Add Waiting List';
	public static readonly ACTION_CLICK_CANCEL_ADD_WAITING_LIST = '[Action] Click Cancel Add Waiting List';

	public static readonly ACTION_CLICK_OPEN_EDIT_WAITING_LIST_MODAL = '[Action] Click Open Edit Waiting List Modal';
	public static readonly ACTION_CLICK_EDIT_WAITING_LIST = '[Action] Click Edit Waiting List';
	public static readonly ACTION_CLICK_CANCEL_EDIT_WAITING_LIST = '[Action] Click Cancel Edit Waiting List';

	public static readonly ACTION_CLICK_OPEN_DELETE_WAITING_LIST_MODAL = '[Action] Click Open Delete Waiting List Modal';
	public static readonly ACTION_CLICK_DELETE_WAITING_LIST = '[Action] Click Delete Waiting List';

	public static readonly ACTION_CLICK_OPEN_DENY_WAITING_LIST_MODAL = '[Action] Click Open Deny Waiting List Modal';
	public static readonly ACTION_CLICK_DENY_WAITING_LIST = '[Action] Click Deny Waiting List';

	public static readonly ACTION_CLICK_MARK_WAITING_LIST_AS_PLANNED = '[Action] Click Mark As Planned Waiting List';

	public static readonly ACTION_OPEN_WAITING_LIST_FILTER = '[Action] Click Open Waiting List Filter';

	/* #################### USER SETTINGS ############## */
	public static readonly ACTION_CLICK_SAVE_USER_SETTINGS = '[Action] Click Save User Settings';
	public static readonly ACTION_CLICK_SAVE_USER_PASSWORD = '[Action] Click Save User Password';

	public static readonly ACTION_CLICK_OPEN_USER_MFA_MODAL = '[Action] Click Open User MFA Modal';
	public static readonly ACTION_CLICK_SAVE_USER_MFA = '[Action] Click Save User MFA';

	public static readonly ACTION_CLICK_SAVE_USER_NOTIFICATIONS_SETTINGS = '[Action] Click Save User Notifications Settings';

	/* #################### PICTURE ############## */
	public static readonly ACTION_CLICK_UPLOAD_PICTURE = '[Action] Click Upload Picture';
	public static readonly ACTION_CLICK_SELECT_PICTURE = '[Action] Click Select Picture';
	public static readonly ACTION_DRAG_DROP_PICTURE = '[Action] Drag&Drop Picture';
	public static readonly ACTION_ZOOM_PICTURE = '[Action] Drag Picture Zoom Slider';
	public static readonly ACTION_DRAG_PICTURE = '[Action] Drag Picture';

	public static readonly ACTION_CLICK_CONFIRM_PICTURE_WIZZARD = '[Action] Click Confirm Picture Wizzard';
	public static readonly ACTION_CLICK_CANCEL_PICTURE_WIZZARD = '[Action] Click Cancel Picture Wizzard';

	/* #################### ONBOARDING ############## */
	public static readonly ACTION_ONBOARDING_TOUR_START = '[Action] Onboarding Tour START';
	public static readonly ACTION_ONBOARDING_TOUR_DROP = '[Action] Onboarding Tour DROPPED';
	public static readonly ACTION_ONBOARDING_TOUR_FINISH = '[Action] Onboarding Tour FINISHED';

	/* #################### GENERAL ############## */
	public static readonly ACTION_CLICK_BUTTON_NEXT_STEP = '[Action] Click Button Next Step';
	public static readonly ACTION_CLICK_ACCEPT_DATA_PRIVACY = '[Action] Click Accept T&C and Data Privacy';
	public static readonly ACTION_CLICK_CANCEL_ABORT = '[Action] Click Cancel / Abort';
	public static readonly ACTION_CLICK_CONSENT_DATA_PRIVACY = '[Action] Click Consent Special Data Privacy';
	public static readonly ACTION_CLICK_CONFIRM_APPOINTMENT_REQUEST = '[Action] Click Confirm Waiting List';
	public static readonly ACTION_CLICK_REQUEST_NEW_TOKEN = '[Action] Click Request New Token';
	public static readonly ACTION_CLICK_RESEND_TOKEN = '[Action] Click Resend Token';
	public static readonly ACTION_CLICK_CANCEL_APPOINTMENT = '[Action] Click Cancel Appointment';
	public static readonly ACTION_CLICK_CANCEL_APPOINTMENT_REQUEST = '[Action] Click Cancel Waiting List';
	public static readonly ACTION_CLICK_CANCEL_APPOINTMENT_FINAL = '[Action] Click Final Cancellation Confirmation';
	public static readonly ACTION_CLICK_ABORT_CANCELLATION = '[Action] Click Abort Appointment Cancellation';
	public static readonly ACTION_CLICK_LINK_PROVIDER_PROFILE = '[Action] Click Link To Care Provider Profile Page';
	public static readonly ACTION_CLICK_CONFIRM_CANCEL_APPOINTMENT = '[Action] Click Confirm Cancellation Appointment';
	public static readonly ACTION_CLICK_CONFIRM_CANCEL_APPOINTMENT_REQUEST = '[Action] Click Confirm Cancellation Waiting List';
	public static readonly ACTION_CLICK_DIAL_PHONE = '[Action] Click Dial Phone Care Provider';
	public static readonly ACTION_CLICK_SEND_EMAIL = '[Action] Click Send Email To Care Provider';

	/* #################### LABELS ############## */
	public static readonly LABEL_SEND = 'Send';
	public static readonly LABEL_SUCCESS = 'Success';
	public static readonly LABEL_FAILED = 'Failed';

	public static readonly LABEL_PERSONAL_DATA = 'Booking Process Personal Data';
	public static readonly LABEL_DATA_PRIVACY = 'Booking Process Data Privacy';
	public static readonly LABEL_TOKEN_VALIDATION = 'Booking Process Token Validation';
	public static readonly LABEL_TOKEN_RESEND = 'Booking Process Token Resend';
	public static readonly LABEL_APPOINTMENT_CONFIRMATION = 'Booking Process Appointment Confirmation';
	public static readonly LABEL_TOKEN_VALIDATION_CANCELATION = 'Booking Process Token Validation Cancellation';
	public static readonly LABEL_APPOINTMENT_CANCELATION = 'Booking Process Appointment Cancellation';
	public static readonly LABEL_APPOINTMENT_CANCELATION_CONFIRMATION = 'Booking Process Appointment Cancellation Confirmation';
}
