import { Injectable } from '@angular/core';

import { select, Store, Action } from '@ngrx/store';

import * as fromAppointment from './appointment.reducer';
import * as AppointmentSelectors from './appointment.selectors';

@Injectable()
export class AppointmentFacade {
	state$ = this.store.pipe(select(AppointmentSelectors.getAppointmentState));
	detail$ = this.store.pipe(select(AppointmentSelectors.getDetail));
	provider$ = this.store.pipe(select(AppointmentSelectors.getProvider));
	patient$ = this.store.pipe(select(AppointmentSelectors.getPatient));
	loading$ = this.store.pipe(select(AppointmentSelectors.getLoading));
	mainTreatment$ = this.store.pipe(select(AppointmentSelectors.getMainTreatment));
	currentTreatmentAssociation$ = this.store.pipe(select(AppointmentSelectors.getCurrentTreatmentAssociation));
	error$ = this.store.pipe(select(AppointmentSelectors.getError));

	constructor(private store: Store<fromAppointment.AppointmentPartialState>) {
	}

	dispatch(action: Action) {
		this.store.dispatch(action);
	}
}
