import { Component, ViewEncapsulation, ChangeDetectionStrategy, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormValidators } from '@ap-angular/shared/utils';

@Component({
	selector: 'ap-angular-delete',
	templateUrl: './delete.component.html',
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class DialogDeleteComponent {

	public form: FormGroup;

	constructor(
		@Inject(MAT_DIALOG_DATA) public data: any,
		private fb: FormBuilder,
		public dialogRef: MatDialogRef<DialogDeleteComponent>
	) {
		this.form = this.fb.group({
			confirmation: [undefined, [Validators.required, FormValidators.noWhitespace, FormValidators.matchString(data.pattern)]]
		});
	}

	public dialogTitle(): string {
		if (this.data && !!this.data.title) {
			let title = this.data.title;
			if (typeof title === 'string') {
				return title;
			}
			const defaultTitle = title.default;
			// TODO find magic solution for  title in case of resize
			//title = title[this._mediaQuerySize];
			//return !isNullOrUndefined(title) ? title : defaultTitle;
			return defaultTitle;
		}
		return undefined;
	}
}
