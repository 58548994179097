export enum ContactEnum {
	category = 'Pages.Provider.Contact.Category',
	subject = 'Pages.Provider.Contact.Subject',
	message = 'Pages.Provider.Contact.Description',
	gender = 'App.General.Salutation',
	firstName = 'App.General.Firstname',
	lastName = 'App.General.Lastname',
	email = 'App.General.Email',
	telephone = 'App.General.Phone',
	practiceName = 'App.General.Practice_Name',
	noventiCustomerNumber = 'App.General.Med_Day_Custom_Number'
}
