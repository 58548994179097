import { AnalyticsConstants } from '@ap-angular/service/analytics';

export class AnalyticsUtils {
	public static getCategoryByApp(application: string): any {
		switch (application) {
			case 'admin':
				return AnalyticsConstants.ADMIN_APP_CATEGORY;
			case 'booking':
				return AnalyticsConstants.BOOKING_APP_CATEGORY;
			case 'partner':
				return AnalyticsConstants.PARTNER_APP_CATEGORY;
			case 'widget':
				return AnalyticsConstants.WIDGET_APP_CATEGORY;
			default:
				return undefined;
		}
	}
}
