/**
 * Appointment Planner - Care Providers REST API
 * Appointment Planner Care Providers Service REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: valeriu.craciun@kronsoft.ro
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Care provider registration status enum data transfer object
 */
export type CareProviderRegistrationStatusEnumDTO = 'NEW' | 'REGISTER_REQUESTED' | 'CONFIRMED' | 'REGISTERED';

export const CareProviderRegistrationStatusEnumDTO = {
    NEW: 'NEW' as CareProviderRegistrationStatusEnumDTO,
    REGISTERREQUESTED: 'REGISTER_REQUESTED' as CareProviderRegistrationStatusEnumDTO,
    CONFIRMED: 'CONFIRMED' as CareProviderRegistrationStatusEnumDTO,
    REGISTERED: 'REGISTERED' as CareProviderRegistrationStatusEnumDTO
};

