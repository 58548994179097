import { Environment } from '@ap-angular/shared/environment';
import { CountryPrefixList } from '@ap-angular/shared/schema';

export class AppUtils {
	public static footerContent(environment: Environment): string {
		const currentYear = new Date().getFullYear();
		const year = (currentYear > 2019) ? `- ${currentYear}` : '';
		const content = `&copy; 2019 ${year} Kronsoft Development SRL`;
		if (environment.production) {
			return content;
		}
		return `${content} (ver: ${environment.version})`;
	}

	public static getCountryCodeList(environment: Environment): Array<string> {
		const countryPrefixList = CountryPrefixList;
		if (environment.production) {
			return countryPrefixList;
		} else {
			const DEV_COUNTRY_CODE = '+40';
			if (!countryPrefixList.includes(DEV_COUNTRY_CODE)) {
				countryPrefixList.push(DEV_COUNTRY_CODE);
			}
			return countryPrefixList;
		}
	}

	public static getComposedPhoneNo(formValue: string, countryPrefix: string): string {

		const phoneNo = formValue.startsWith('(0)') ? formValue.slice(3) : formValue;
		return countryPrefix + phoneNo;
	}

	public static getTelephoneFormatted(phone: string): string {
		if (phone && phone.startsWith('+')) {
			return phone.slice(3);
		}

		if (phone && phone.startsWith('00')) {
			return phone.slice(4);
		}
		return phone;
	}

	public static getPrefixFromPhone(phone: string): string {
		if (phone && phone.startsWith('+') && phone.length > 3) {
			return phone.slice(0, 3);
		}

		if (phone && phone.startsWith('00') && phone.length > 4) {
			return phone.slice(0, 4);
		}
		return undefined;
	}

	public static mergeEntryToList(list: Array<any>, entry: any, eventId: string | number): Array<any> {
		if (!eventId) {
			list.push(entry);
		} else {
			list[list.findIndex((item) => item.id === eventId)] = entry;
		}
		list.sort((a, b) => a.name.localeCompare(b.name));
		return [...list];
	}

	public static removeEmptyKeyFromObject(obj: any): any {
		const newObject = obj;
		Object.keys(newObject).forEach((key) => (!newObject[key] || !newObject[key]?.length) && delete newObject[key]);
		return newObject;
	}

	public static copy(document, value: string): Promise<any> {
		const selBox = document.createElement('textarea');
		selBox.style.position = 'fixed';
		selBox.style.left = '0';
		selBox.style.top = '0';
		selBox.style.opacity = '0';
		selBox.value = value;
		document.body.appendChild(selBox);

		/** Happy hack for big sister of MSIE, named iOS */
		if (navigator.userAgent.match(/ipad|ipod|iphone/i)) {
			selBox.setAttribute('readonly', '');

			const range = document.createRange();
			range.selectNodeContents(selBox);

			const selection = window.getSelection();
			selection.removeAllRanges();
			selection.addRange(range);

			selBox.setSelectionRange(0, selBox.value.length);
		} else {
			selBox.focus();
			selBox.select();
		}

		setTimeout(() => {
			document.body.removeChild(selBox);
		})

		return Promise.resolve().then(() => {
			try{
				return document.execCommand('copy');
			} catch(e) {
				return false;
			}
		});
	}
}
