/**
 * Appointment Planner - Online Appointments REST API
 * Appointment Planner Online Appointments Service REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: valeriu.craciun@kronsoft.ro
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Referer as online or classic appointment source
 */
export type AppointmentRefererEnumDTO = 'ONLINE' | 'CLASSIC';

export const AppointmentRefererEnumDTO = {
    ONLINE: 'ONLINE' as AppointmentRefererEnumDTO,
    CLASSIC: 'CLASSIC' as AppointmentRefererEnumDTO
};

