<mat-toolbar class="template-header" color="light">
    <div class="element-spacer"></div>

    <ap-angular-logo class="component-title"></ap-angular-logo>

    <div class="element-spacer"></div>

    <button mat-button color="primary" (click)="logout()" *ngIf="isLoggedIn">{{'Pages.Logout.Label' | translate}}</button>
</mat-toolbar>

<section class="emptyScreen margin-horizontal">
    <div class="block padding-vertical">
        <div class="relative padding-top-L">
            <mat-icon>cloud_off</mat-icon>
            <h3 class="no-margin">{{'Page.Error_Not_Found.Text_1' | translate}}</h3>
            <div class="margin-bottom-M">{{'Page.Error_Not_Found.Text_2' | translate}}</div>
            <button mat-raised-button color="warn" (click)="reload()">{{'Page.Error_Not_Found.Button' | translate | uppercase}}</button>
        </div>
    </div>
</section>

<mat-toolbar class="template-footer" color="light">
    <div class="copyright" [innerHtml]="content"></div>
</mat-toolbar>
