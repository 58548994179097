import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { UserService } from '@ap-angular/service/user';

@Injectable()
export class LogoutGuard implements CanActivate {

	constructor(
		private userService: UserService,
		private router: Router
	) {
	}

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
		const LOGGED = this.userService.isLoggedIn();
		if (LOGGED) {
			this.userService.logout(true);
			setTimeout(() => this.router.navigate([state.url]), 400);
		}
		return !LOGGED;
	}
}
