export class PatientUtils {
	private static getName(patient: any): string {
		return `${patient.lastname ? patient.lastname : ''} ${patient.firstname ? patient.firstname : ''}`;
	}

	public static displayName(patient: any, gender = true): string {
		if (!patient) {
			return undefined;
		}
		return `${gender ? (patient.gender ? patient.gender : '') : ''}
					${this.getName(patient)}`;
	}

	public static displayPhone(patient: any): string {
		if (!patient) {
			return undefined;
		}
		return `${patient.telephone ? patient.telephone : ''}`;
	}

	public static checkRequiredPatientBirthdateOrPhoneNumber(error: any): boolean {
		const additionalFields: string[] = error?.additionalFields;
		const phoneBirthdateFields = ['telephone', 'birthdate'];
		if (additionalFields?.length === phoneBirthdateFields.length && additionalFields.every(additionalField => phoneBirthdateFields.includes(additionalField))) {
			return true;
		}

		return false;
	}
}
