/**
 * Appointment Planner - Patients REST API
 * Appointment Planner Patients Service REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: valeriu.craciun@kronsoft.ro
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Search field enum
 */
export type SearchFieldEnumDTO = 'EMAIL' | 'TELEPHONE' | 'LAST_NAME' | 'FIRST_NAME' | 'BIRTHDATE';

export const SearchFieldEnumDTO = {
    EMAIL: 'EMAIL' as SearchFieldEnumDTO,
    TELEPHONE: 'TELEPHONE' as SearchFieldEnumDTO,
    LASTNAME: 'LAST_NAME' as SearchFieldEnumDTO,
    FIRSTNAME: 'FIRST_NAME' as SearchFieldEnumDTO,
    BIRTHDATE: 'BIRTHDATE' as SearchFieldEnumDTO
};

