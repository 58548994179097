import { Injectable } from '@angular/core';

import { select, Store, Action } from '@ngrx/store';

import * as fromApplication from './application.reducer';
import * as ApplicationSelectors from './application.selectors';

@Injectable()
export class ApplicationFacade {
	version$ = this._store.pipe(select(ApplicationSelectors.getVersion));
	update$ = this._store.pipe(select(ApplicationSelectors.getVersionUpdate));
	language$ = this._store.pipe(select(ApplicationSelectors.getLanguage));
	screen$ = this._store.pipe(select(ApplicationSelectors.getMediaQuerySize));
	showSearch$ = this._store.pipe(select(ApplicationSelectors.getShowSearch));
	sideNav$ = this._store.pipe(select(ApplicationSelectors.getSidenav));
	positionOfSearchInput$ = this._store.pipe(select(ApplicationSelectors.getPositionOfSearchInput));

	constructor(private readonly _store: Store<fromApplication.ApplicationPartialState>) {
	}

	dispatch(action: Action) {
		this._store.dispatch(action);
	}
}
