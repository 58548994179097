/**
 * Appointment Planner - Care Providers REST API
 * Appointment Planner Care Providers Service REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: valeriu.craciun@kronsoft.ro
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

import { AttachmentsResponseDTO } from '../model/models';
import { MessageTemplateDTO } from '../model/models';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';



@Injectable({
  providedIn: 'root'
})
export class MessageTemplateService {

    protected basePath = 'http://localhost:8510';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key,
                        (value as Date).toISOString().substr(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * Create new message templates
     * @param messageTemplateDTO System MessageTemplate Request Body
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createMessageTemplate(messageTemplateDTO: MessageTemplateDTO, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<MessageTemplateDTO>;
    public createMessageTemplate(messageTemplateDTO: MessageTemplateDTO, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<MessageTemplateDTO>>;
    public createMessageTemplate(messageTemplateDTO: MessageTemplateDTO, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<MessageTemplateDTO>>;
    public createMessageTemplate(messageTemplateDTO: MessageTemplateDTO, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (messageTemplateDTO === null || messageTemplateDTO === undefined) {
            throw new Error('Required parameter messageTemplateDTO was null or undefined when calling createMessageTemplate.');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<MessageTemplateDTO>(`${this.configuration.basePath}/message-templates`,
            messageTemplateDTO,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * get attachment by attachment UUID
     * @param id ID
     * @param uuid path Param UUID
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAttachment(id: number, uuid: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/pdf'}): Observable<Blob>;
    public getAttachment(id: number, uuid: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/pdf'}): Observable<HttpResponse<Blob>>;
    public getAttachment(id: number, uuid: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/pdf'}): Observable<HttpEvent<Blob>>;
    public getAttachment(id: number, uuid: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/pdf'}): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getAttachment.');
        }
        if (uuid === null || uuid === undefined) {
            throw new Error('Required parameter uuid was null or undefined when calling getAttachment.');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/pdf'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        return this.httpClient.get(`${this.configuration.basePath}/message-template/${encodeURIComponent(String(id))}/attachment/${encodeURIComponent(String(uuid))}`,
            {
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get message template by id and type
     * @param personId Care provider id
     * @param category Category of template
     * @param type Category of template
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getMessageTemplateForPerson(personId: number, category: 'REGISTER' | 'MFA_CODE_CONFIRMATION' | 'NEW_APPOINTMENT' | 'BOOKING_CODE_CONFIRMATION' | 'BOOKING_CODE_CANCELLATION' | 'BOOKING_CONFIRMATION' | 'BOOKING_CANCELLATION' | 'BOOKING_REMINDER' | 'BOOKING_UPDATE', type: 'SMS' | 'EMAIL', observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<MessageTemplateDTO>;
    public getMessageTemplateForPerson(personId: number, category: 'REGISTER' | 'MFA_CODE_CONFIRMATION' | 'NEW_APPOINTMENT' | 'BOOKING_CODE_CONFIRMATION' | 'BOOKING_CODE_CANCELLATION' | 'BOOKING_CONFIRMATION' | 'BOOKING_CANCELLATION' | 'BOOKING_REMINDER' | 'BOOKING_UPDATE', type: 'SMS' | 'EMAIL', observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<MessageTemplateDTO>>;
    public getMessageTemplateForPerson(personId: number, category: 'REGISTER' | 'MFA_CODE_CONFIRMATION' | 'NEW_APPOINTMENT' | 'BOOKING_CODE_CONFIRMATION' | 'BOOKING_CODE_CANCELLATION' | 'BOOKING_CONFIRMATION' | 'BOOKING_CANCELLATION' | 'BOOKING_REMINDER' | 'BOOKING_UPDATE', type: 'SMS' | 'EMAIL', observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<MessageTemplateDTO>>;
    public getMessageTemplateForPerson(personId: number, category: 'REGISTER' | 'MFA_CODE_CONFIRMATION' | 'NEW_APPOINTMENT' | 'BOOKING_CODE_CONFIRMATION' | 'BOOKING_CODE_CANCELLATION' | 'BOOKING_CONFIRMATION' | 'BOOKING_CANCELLATION' | 'BOOKING_REMINDER' | 'BOOKING_UPDATE', type: 'SMS' | 'EMAIL', observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (personId === null || personId === undefined) {
            throw new Error('Required parameter personId was null or undefined when calling getMessageTemplateForPerson.');
        }
        if (category === null || category === undefined) {
            throw new Error('Required parameter category was null or undefined when calling getMessageTemplateForPerson.');
        }
        if (type === null || type === undefined) {
            throw new Error('Required parameter type was null or undefined when calling getMessageTemplateForPerson.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (personId !== undefined && personId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>personId, 'person_id');
        }
        if (category !== undefined && category !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>category, 'category');
        }
        if (type !== undefined && type !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>type, 'type');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<MessageTemplateDTO>(`${this.configuration.basePath}/message-templates/find-one`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get message templates
     * @param systemTemplates Filter only system templates (default &#x3D; \&#39;true\&#39;)
     * @param personId Person Id
     * @param showAll If true, show editable and non-editable templates templates (default &#x3D; \&#39;false\&#39;)
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getMessageTemplates(systemTemplates?: boolean, personId?: number, showAll?: boolean, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<MessageTemplateDTO>>;
    public getMessageTemplates(systemTemplates?: boolean, personId?: number, showAll?: boolean, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<MessageTemplateDTO>>>;
    public getMessageTemplates(systemTemplates?: boolean, personId?: number, showAll?: boolean, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<MessageTemplateDTO>>>;
    public getMessageTemplates(systemTemplates?: boolean, personId?: number, showAll?: boolean, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (systemTemplates !== undefined && systemTemplates !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>systemTemplates, 'systemTemplates');
        }
        if (personId !== undefined && personId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>personId, 'personId');
        }
        if (showAll !== undefined && showAll !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>showAll, 'showAll');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<Array<MessageTemplateDTO>>(`${this.configuration.basePath}/message-templates`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update an existing message templates
     * @param messageTemplateDTO System MessageTemplate Request Body
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateMessageTemplate(messageTemplateDTO: MessageTemplateDTO, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<MessageTemplateDTO>;
    public updateMessageTemplate(messageTemplateDTO: MessageTemplateDTO, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<MessageTemplateDTO>>;
    public updateMessageTemplate(messageTemplateDTO: MessageTemplateDTO, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<MessageTemplateDTO>>;
    public updateMessageTemplate(messageTemplateDTO: MessageTemplateDTO, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (messageTemplateDTO === null || messageTemplateDTO === undefined) {
            throw new Error('Required parameter messageTemplateDTO was null or undefined when calling updateMessageTemplate.');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.put<MessageTemplateDTO>(`${this.configuration.basePath}/message-templates`,
            messageTemplateDTO,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Upload attachment for message template
     * @param file File to be uploaded
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public uploadAttachment(file?: Blob, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<AttachmentsResponseDTO>;
    public uploadAttachment(file?: Blob, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<AttachmentsResponseDTO>>;
    public uploadAttachment(file?: Blob, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<AttachmentsResponseDTO>>;
    public uploadAttachment(file?: Blob, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any; };
        let useForm = false;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: this.encoder});
        }

        if (file !== undefined) {
            formParams = formParams.append('file', <any>file) as any || formParams;
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<AttachmentsResponseDTO>(`${this.configuration.basePath}/message-template/attachment`,
            convertFormParamsToString ? formParams.toString() : formParams,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
