/**
 * Appointment Planner - Users REST API
 * Appointment Planner Users Service REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: valeriu.craciun@kronsoft.ro
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Notification type enum dto
 */
export type NotificationTypeEnumDTO = 'NEW_RELEASE_NOTES';

export const NotificationTypeEnumDTO = {
    NEWRELEASENOTES: 'NEW_RELEASE_NOTES' as NotificationTypeEnumDTO
};

