import { PAGE } from '@ap-angular/shared/schema';
import { AppTourDismissDTO, AppTourDTO } from '@ap-angular/api-kds/onboarding';

const BUTTON_SKIP = {type: 'mat-button', color: 'primary', name: 'Interactive_Tour.Button_Cancel', close: true};

export const AppProviderTourDropping: AppTourDismissDTO = {
	step: 999,
	overlay: true,
	description: `Interactive_Tour.Drop.Description`,
	reload: `Interactive_Tour.Drop.Checkbox_Content`,
	buttons: [
		{
			type: 'mat-button',
			color: 'primary',
			name: 'Interactive_Tour.Drop.Button_No',
			revert: true
		},
		{
			type: 'mat-flat-button',
			color: 'primary',
			name: 'Interactive_Tour.Drop.Button_Yes',
			drop: true
		}
	]
};

export const AppProviderTour: Array<AppTourDTO> = [
	{
		// STEP: INTRO
		step: 0,
		overlay: true,
		title: {
			content: 'Interactive_Tour.Step_0.Title'
		},
		description: `Interactive_Tour.Step_0.Description`,
		buttons: [BUTTON_SKIP,
			{
				type: 'mat-flat-button',
				color: 'primary',
				name: 'Start',
				url: PAGE.PROVIDER_PROFILE.fullpath,
				stepUpdate: 1
			}
		],
		hideMinimize: true
	},
	{
		// STEP: VERIFY PROFILE DATA
		step: 1,
		percent: 0,
		info: '1/5',
		title: {
			content: 'Interactive_Tour.Step_1.Title'
		},
		description: 'Interactive_Tour.Step_1.Description',
		detail: `Interactive_Tour.Step_1.Details`,
		buttons: [BUTTON_SKIP,
			{
				type: 'mat-flat-button',
				color: 'primary',
				name: 'OK',
				url: PAGE.PROVIDER_RESOURCE.fullpath,
				stepUpdate: 2
			}
		]
	},
	{
		// STEP: ADD/EDIT RESOURCE
		step: 2,
		percent: 20,
		info: '2/5',
		title: {
			content: 'Interactive_Tour.Step_2.Title'
		},
		description: `Interactive_Tour.Step_2.Description`,
		detail: `Interactive_Tour.Step_2.Details`,
		buttons: [BUTTON_SKIP]
	},
	{
		// STEP: ADD RESOURCE
		step: 3,
		percent: 20,
		info: '2/5',
		title: {
			content: 'Interactive_Tour.Step_3.Title'
		},
		description: `Interactive_Tour.Step_3.Description`,
		detail: `Interactive_Tour.Step_3.Details`,
		buttons: [BUTTON_SKIP]
	},
	{
		// STEP: BOOKING HOURS
		step: 4,
		percent: 40,
		info: '3/5',
		title: {
			content: 'Interactive_Tour.Step_4.Title'
		},
		description: `Interactive_Tour.Step_4.Description`,
		detail: `Interactive_Tour.Step_4.Details`,
		buttons: [BUTTON_SKIP]
	},
	{
		// STEP: ADD BOOKING HOURS
		step: 5,
		percent: 40,
		info: '3/5',
		title: {
			content: 'Interactive_Tour.Step_5.Title'
		},
		description: `Interactive_Tour.Step_5.Description`,
		detail: `Interactive_Tour.Step_5.Details`,
		buttons: [BUTTON_SKIP,
			{
				type: 'mat-flat-button',
				color: 'primary',
				name: 'OK',
				stepUpdate: 6
			}
		]
	},
	{
		// STEP: ONLINE BOOKING HOURS
		step: 6,
		percent: 60,
		info: '4/5',
		title: {
			content: 'Interactive_Tour.Step_6.Title'
		},
		description: `Interactive_Tour.Step_6.Description`,
		detail: `Interactive_Tour.Step_6.Details`,
		buttons: [BUTTON_SKIP]
	},
	{
		// STEP: ADD ONLINE BOOKING HOURS
		step: 7,
		percent: 60,
		info: '4/5',
		title: {
			content: 'Interactive_Tour.Step_7.Title'
		},
		description: `Interactive_Tour.Step_7.Description`,
		detail: `Interactive_Tour.Step_7.Details`,
		buttons: [BUTTON_SKIP,
			{
				type: 'mat-flat-button',
				color: 'primary',
				url: PAGE.PROVIDER_TREATMENT.fullpath,
				name: 'OK',
				stepUpdate: 8
			}
		]
	},
	{
		// STEP: TREATMENT
		step: 8,
		percent: 80,
		info: '5/5',
		title: {
			content: 'Interactive_Tour.Step_8.Title'
		},
		description: `Interactive_Tour.Step_8.Description`,
		detail: `Interactive_Tour.Step_8.Details`,
		buttons: [BUTTON_SKIP]
	},
	{
		// STEP: ADD TREATMENT
		step: 9,
		percent: 80,
		info: '5/5',
		title: {
			content: 'Interactive_Tour.Step_9.Title'
		},
		description: `Interactive_Tour.Step_9.Description`,
		detail: `Interactive_Tour.Step_9.Details`,
		buttons: [BUTTON_SKIP]
	},
	{
		// STEP: FINISH
		step: 10,
		info: '5/5',
		title: {
			content: 'Interactive_Tour.Step_10.Title',
			color: 'accent'
		},
		icon: {
			name: 'check_circle_outline',
			zoom: '4',
			color: 'accent'
		},
		detail: `Interactive_Tour.Step_10.Details`,
		buttons: [
			{
				type: 'mat-flat-button finish',
				color: 'primary',
				name: 'Interactive_Tour.Step_10.Button_Finalise',
				stepUpdate: 1000
			}
		],
		hideMinimize: true
	}
];
